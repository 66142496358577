// import "../../assets/css/home.css";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {_fetch_user_wallet_data, _get_profile} from '../common/axios_services';
import { Modal, Col, Row, Drawer } from "antd";
import side_banner5 from "../assets/images/homepage/side_banner_4.png";
import topHeader1 from "../assets/images/homepage/topHeader1.png";
import appImg from "../assets/images/homepage/appImg.png";
import chef from "../assets/images/homepage/chef.png";
import bannerMeal from "../assets/images/homepage/bannerMeal.png";
import SearchRestaurant from "../components/SearchRestaurant";
import FooterDisplay from "../components/footer";
import DealsCard from "../components/DealsCard";
import whatsappIcon from "../assets/images/icons/whatsappEmoji.svg"
import whatsappGreenIcon from "../assets/images/icons/whatsappGreenEmoji.svg"
import { PinActivation } from "../components/PinActivation";


const Homepage = () => {
    let navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [partnerModalOpen, setPartnerModalOpen] = useState(false);
    const [vendorPartnerModalOpen, setVendorPartnerModalOpen] = useState(false);
    const [appModalOpen, setAppModalOpen] = useState(false);
    const [isVendor, setIsVendor] = useState(false);
    const [isRestaurant, setIsRestaurant] = useState(false);
    const [userSignedIn, setUserSignedIn] = useState(false);
    const [userloginPinSet, setUserLoginPinSet] = useState(false);
    const [accountId, setAccountId] = useState("");
    const [loading, setLoading] = useState(true);
    
  
  
    const getUserProfile = async () => {
      try{
        setLoading(true); // Start loading
        let result = await _get_profile();
        setIsVendor(result?.data?.data.is_so);
        setIsRestaurant(result?.data?.data.is_restaurant);
        setAccountId(result?.data?.data.account_id)
        setUserSignedIn(true)
        // ACTIVATE PIN
        setUserLoginPinSet(result?.data?.data.login_pin_set);
      }
      catch(e){
        if (e.response) {
          if (e.response.data.message === "Unauthorized") {
           setUserSignedIn(false)
            localStorage.removeItem('konnect_token')
          } 
        }
      } 
      finally {
        setLoading(false); // End loading after data fetch
      }
    }

  
    // JOIN NOW MODAL
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  
  
   /* EXPERIENCE APP MODAL */
    const showAppModal = () => {
      setAppModalOpen(true); 
    };
    const handleAppCancel = () => {
      setAppModalOpen(false);
    };
  
    /* PARTNERSHIP MODAL */
    const showPartnerModal = () => {
        setPartnerModalOpen(true); //signed in but not a vendor
    };
    // is not vendor
    const handlePartnerCancel = () => {
      setPartnerModalOpen(false);
    };
  
  
  
    useEffect(() => {
      getUserProfile()
    }, []);

    return (
        <div>
            {/* Hero Section */}
            {
              (userSignedIn && !loading && !userloginPinSet ) && (
                  <PinActivation 
                  open={!userloginPinSet} 
                  toClose={setUserLoginPinSet} 
                  accountId={accountId}
                />
              )
            }

            {/* )} */}
            <div>
                <Row
                align={"middle"}
                justify={"center"}
                className="h-auto  w-full px-12 "
                gutter={{ sm: 4, md: 24, lg: 48 }}
                >
                  <Col xs={24} md={12}>
                    <div className="">
                      <img className="bg-[#f5f5f5] w-full" src={topHeader1} alt="hero_image" />
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className=" max-md:text-center max-[767px]:mt-20  text_h3 gap-y-2 flex flex-col">
                      <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Family & Friends
                      </p>
                      <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Eats Cheaper
                      </p>
                      <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        With <span className="text-[#44843F] font-bold">Konnect.</span>
                      </p>
                      <p className="lg:text-8xl xl:text-9xl font-bold text-6xl text_h3">
                        30% Discount
                      </p>
                      </div>
                      <div className="flex items-center gap-3 max-md:mt-5 mt-3  mb-3 max-md:justify-center">
                        <button onClick={showAppModal} className="rounded-button focus:outline-none ">
                          How it works
                        </button>
                      </div>
                      <div className="max-md:my-20 " >
                        <SearchRestaurant />
                      </div>
                  </Col>
                </Row>
                {/*  */}
            </div>
        {/* Third Section */}
         <div>
            <Row
              align={"middle"}
              justify={"center"}
              className="h-auto w-full px-12 my-36 "
            >
              <Col xs={24} md={12}>
                <div className="w-full flex justify-center">
                  <img src={chef} alt="chef_image" />
                </div>
              </Col>   
              <Col xs={24} md={12}>
                <div className="text_h3 gap-y-2 w-full max-sm:mt-7 text-center flex justify-center flex-col">
                  <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                    You cook, we sell..
                  </p>
                  <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                  We'll grow your
                  </p>
                  <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                  Food business
                  </p>
                </div>
                  <div className="w-full flex justify-center items-center max-md:mt-5 mt-3  max-md:justify-center">
                    <button
                      onClick={showPartnerModal}
                      className="flex justify-end rounded-button focus:outline-none "
                    >
                    Read More
                    </button>
                  </div>
              </Col>

            </Row>
         </div>
  
        {/* Fourth Section  */}
        <DealsCard />
  
  
        {/* Fifth Section */}
        <div>
            <Row
              align={"middle"}
              justify={"center"}
              className="h-auto w-full px-12 my-36"
            >
                <Col xs={24} md={12}>
                    <div className="text_h3 gap-y-2 flex flex-col text-center md:text-left ">
                        <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                            Join the
                            <span className="text-[#44843F] font-bold"> Konnect</span> 
                        </p>
                        <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                            Marketing team
                        </p>
                        <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                            Earn monthly
                        </p>
                    </div>
                    <div className="flex items-center gap-3 max-md:mt-5 mt-3  mb-3 max-md:justify-center">
                      {
                        !userSignedIn ? (  
                        <a 
                          href="/signin" 
                          className="rounded-button"
                        >Join Now</a>
                        ) : 
                        (
                          <button onClick={showModal} className="rounded-button focus:outline-none ">
                            Join Now
                         </button>
                        )
                      }

                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className="w-full flex justify-center">
                    <img src={side_banner5} alt="_image" />
                    </div>
                </Col>
  
            </Row>
        </div>
  
  
        {/* Sixth Section */}
        <div className="pb-24" >
            <Row
              align={"middle"}
              justify={"center"}
              className="h-full w-full px-12 my-36 "
            >
                <Col xs={24} md={12}>
                    <div className=" text_h3 gap-y-2 flex flex-col text-center md:text-left">
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Think Breakfast
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Lunch & Dinner
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Think <span className="text-[#44843F] font-bold"> Konnect</span>
                    </p>
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className="w-full flex justify-center">
                    <img src={bannerMeal} alt="_image" />
                    </div>
                </Col>
  
            </Row>
        </div>
  
        {/* FOOTER */}
        <FooterDisplay />


  
        {/* MODAL DRAWERS */}

        {/* JOIN MARKETING TEAM MODAL */}
        <Drawer
          open={isModalOpen}
          footer={null}
          onClose={handleCancel}
          placement="bottom"
          className="partnerModal"
        >
          <div >
            <h2 className="text-green-800 font-bold flex items-center my-4 mt-8 text-5xl" >Our Marketing Team</h2>
            <div>
              <ul className="numbered-list">
                <li className="marker:text-[#44843F]">They meet with people physically or online</li>
                <li className="marker:text-[#44843F]">They use their referral code to sign-up & download the app.</li>
                <li className="marker:text-[#44843F]">They are paid a monthly salary</li>
              </ul>
              <div className="flex flex-col mt-10 gap-y-3" >
                <p>INTERESTED ? </p>
                <a className="bg-[#1d8d46] text-white rounded-full py-4 px-10 w-3/4  " href="https://chat.whatsapp.com/Lm7N5XFOU6UA4TmxTUUQk4"  >
                  <span  className="text-white flex items-center gap-3 font-gilroyBold text-[14px]">
                    <img src={whatsappIcon} alt="" />
                    JOIN US VIA WHATSAPP
                  </span>
                </a>
                  {/* <span  className="bg-green-800 text-white  font-gilroyRegular rounded-full text-[14px] hover: py-4 px-10 w-3/5 "> <a href="https://chat.whatsapp.com/Lm7N5XFOU6UA4TmxTUUQk4"  >JOIN US VIA WHATSAPP </a> </span> */}
              </div>
            </div>
          </div>
        </Drawer>
      
  
        {/* IS VENDOR PARTNERSHIP MODAL */}
        <Drawer
          open={partnerModalOpen}
          footer={null}
          onClose={handlePartnerCancel} 
          placement="bottom"
          className="partnerModal"
        >
          <div >
            <h2 className="text-green-800 font-bold flex items-center my-4 mt-8 text-5xl ">
              Become a Restaurant & Earn More
            </h2>
            <ul className="list-disc pl-5 space-y-8 mt-2">
              <li className="marker:text-[#44843F]">Only a <span className="font-bold" >Food Vendor</span> can become a restaurant </li>
              <li className="marker:text-[#44843F]">Reach more customers than ever (onsite and online)</li>
              <li className="marker:text-[#44843F]">We sell, deliver, and help you grow your business.</li>
              <li className="marker:text-[#44843F]">Restaurants can earn more than a million Naira weekly.</li>
            </ul>
            <div className=" flex items-center mt-10  gap-3" >
              <span className="font-bold">Interested? Contact us via </span>
              <span className="text-white flex items-center gap-3 font-gilroyBold">
                <a href="http://wa.me/2349071184141"  >
                  <img src={whatsappGreenIcon} width={28} height={28} alt="" />
                </a>
              </span>
            </div>
          </div>
        </Drawer>

  
        {/* EXPERIENCE APP MODAL */}
        <Drawer
          open={appModalOpen}
          footer={null}
          onClose={handleAppCancel}
          placement="bottom"
          className="appModal"
          size="large"
        >
          <div >
            <h2 className="text-green-800  text-5xl my-4" >Eat Cheaper on Konnect </h2>
            <div className="flex flex-col gap-y-8">
              <p>
                <span role="img" aria-label="cart">🛒</span> 10% discount on 1st 3 orders
              </p>
              <p>
                <span role="img" aria-label="thumbs-up">⭐</span> <span className="font-bold" >30% discount</span>on 4th order
              </p>
              <p>
                <span role="img" aria-label="thumbs-up">✨</span>Discounts for 1st to 4th orders are repeatedly within set duration
              </p>
              <p>
                <span role="img" aria-label="gift">🎁</span> Sign-up today & get <span className="font-bold">₦900</span>extra discount <i>(7 days validity)</i> 
              </p>
            </div>
          </div>
        </Drawer>
      </div>
       
        // </div>
    )
}

export default Homepage;