import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { ReactComponent as HomeIcon } from "../assets/images/icons/home.svg";
import { ReactComponent as Wise9jaIcon } from "../assets/images/icons/pocket.svg";
import { ReactComponent as DiscoverIcon } from "../assets/images/icons/compass.svg";
import { ReactComponent as HistoryIcon } from "../assets/images/icons/archive.svg";
import { ReactComponent as ProfileIcon } from "../assets/images/icons/user.svg";
import { ReactComponent as CartIcon } from "../assets/images/icons/shopping-cart.svg";


const FooterDisplay = props => {
    const [currPage, setCurrPage] = useState("");
    useEffect(() => {
        let currLocation = window.location.pathname;
        setCurrPage(currLocation);
    }, [window.location.href])
    return (
        <div className={`footer-cover ${props.short ? "short" : ""}`}>
            <div className="footer">
                <div className="footer-display">
                    <div>
                        <ul>
                            <li>
                                <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/home">
                                    <div className="icon-cover">
                                        {
                                            currPage === "/home" ?
                                                <div>
                                                    <ion-icon name="home"></ion-icon>
                                                </div>
                                                :
                                                <div>
                                                    <ion-icon name="home-outline"></ion-icon>
                                                </div>
                                        }
                                        {/* <ion-icon name="home-outline"></ion-icon> */}
                                    </div>
                                    <div>
                                        <h3>Home</h3>
                                    </div>
                                </NavLink>
                            </li>
                            {/* ./orders & /wise9ja */}
                            <li>
                                <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/restaurant">
                                    <div className="icon-cover">
                                        {
                                            currPage === "/restaurant" ?
                                                <div>
                                                    {/* <ion-icon name="bag"></ion-icon> */}
                                                    <ion-icon name="file-tray"></ion-icon>
                                                </div>
                                                :
                                                <div>
                                                    <ion-icon name="file-tray-outline"></ion-icon>
                                                </div>
                                        }
                                    </div>
                                    <div>
                                        <h3>Restaurant</h3>
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/shop">
                                    <div className="icon-cover">
                                        {
                                            currPage === "/shop" ?
                                                <div>
                                                    {/* <ion-icon name="cart"></ion-icon> */}
                                                    <ion-icon name="basket"></ion-icon>
                                                </div>
                                                :
                                                <div>
                                                    {/* <ion-icon name="cart-outline"></ion-icon> */}
                                                    <ion-icon name="basket-outline"></ion-icon>
                                                </div>
                                        }
                                    </div>
                                    <div>
                                        <h3>Shop</h3>
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/wallet">
                                    <div className="icon-cover">
                                        {
                                            currPage === "/wallet" ?
                                                <div>
                                                    {/* <ion-icon name="wallet"></ion-icon> */}
                                                    <ion-icon name="card"></ion-icon>
                                                </div>
                                                :
                                                <div>
                                                    {/* <ion-icon name="wallet-outline"></ion-icon> */}
                                                    <ion-icon name="card-outline"></ion-icon>
                                                </div>
                                        }
                                    </div>
                                    <div><h3>Wallet</h3></div></NavLink>
                            </li>
                            <li>
                                <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/settings">
                                    <div className="icon-cover">
                                        {
                                            currPage === "/settings" ?
                                                <div>
                                                    <ion-icon name="settings"></ion-icon>
                                                </div>
                                                :
                                                <div>
                                                    <ion-icon name="settings-outline"></ion-icon>
                                                </div>
                                        }
                                    </div>
                                    <div><h3>Account</h3></div></NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default FooterDisplay;