import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import { _all_product } from "../common/axios_services";
import { notification, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { addToCart, updateCart } from '../slices/cartSlice';
import NumberFormat from "react-number-format";
import { LoadingOutlined } from '@ant-design/icons/lib/icons';
import { Link } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../assets/images/icons/plus.svg";

const OtherProducts = () => {


    const [allProducts, setAllProducts] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [pageSize] = React.useState(24);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message
        });
    };

    const getAllProducts = async () => {
        setLoadingData(true);
        setAllProducts([]);
        try {
            const products = await _all_product({ page: page, per_page: 5, search: "" });
            setAllProducts(products.data.data);
            setLoadingData(false);
            setTotal(products.data.meta.total);
            // console.log(products)
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err?.code === "ERR_NETWORK") {
                    openNotificationWithIcon("error", "An error occurred while fetching product data. Please check network and try again");
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon("error", "An error occurred while fetching product data. Please reload page to try again");
            }
        }
    }
    useEffect(() => {
        getAllProducts();
    }, []);

    let skeleton = [];
    for (let i = 0; i < 10; i++) {
        skeleton.push(<div>
            <Skeleton.Image style={{ width: "100%", height: "15rem" }} active />
            <Skeleton.Button style={{ marginTop: 10 }} active />
        </div>)
    }


    const shopBreakpoints = {
        0: {
            slidesPerView: 2.5
        },
        600: {
            slidesPerView: 2.5
        },
        1000: {
            slidesPerView: 5.2
        }
    }

    // add to cart
    const dispatch = useDispatch();
    const [addToCartButton, setAddToCartButton] = useState(false);

    const cart = useSelector(state => state.userCart.cart)

    const uploadToCart = ({ product }) => {
        const get_cart = JSON.parse(localStorage.getItem('konnect_cart'))
        // TImestamp
        const timestamp = Date.now().toString();
        // Generate four random numbers
        const randomNumbers = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
        setAddToCartButton(true);
        if (!get_cart) {
            const set_cart = {
                product_id: `${timestamp + randomNumbers}`,
                order_id: `${timestamp + randomNumbers}`,
                data: [
                    {
                        id: `${1}`,
                        order_id: `${timestamp + randomNumbers}`,
                        product_id: product.id,
                        product_name: product.product_name,
                        product_image: product.product_image,
                        product_price: product.konnect_price,
                        description: product.description,
                        weight: product.weight,
                        discount: product.konnect_price * product.discount_rate * 0.01,
                        discount_rate: product.discount_rate,
                        quantity: 1,
                        timestamp: Date.now(),
                        total_weight: 1 * product.weight,
                        total_price: 1 * product.konnect_price,
                        cashback: product?.subcat?.cashback * product?.konnect_price * 0.01,
                        subcat: product?.subcat
                    }
                ],
                total_amount: product.konnect_price,
                total_weight: product.weight,
                coupon: 0,
                fee: 0,
                tax: 0,
                discount: 0,
                charges: 0,
                total_item: 1,
                delivery_fee: 0,
                delivery_opt: "home",
                delivery_date: "28-05-2023",
                delivery_data: {}
            }
            dispatch(addToCart(set_cart))
            setAddToCartButton(false);
            openNotificationWithIcon("success", "Product added to Cart Successfully");
            localStorage.setItem('konnect_cart', JSON.stringify(set_cart))
        } else {
            let index = get_cart.data.findIndex(x => x.product_id == product.id);
            let newProd = get_cart;
            if (index == -1) {
                const new_cart = {
                    id: get_cart.data.length + 1,
                    order_id: get_cart.order_id,
                    product_id: product.id,
                    product_name: product.product_name,
                    product_image: product.product_image,
                    product_price: product.konnect_price,
                    weight: product.weight,
                    discount: product.konnect_price * product.discount_rate * 0.01,
                    discount_rate: product.discount_rate,
                    description: product.description,
                    quantity: 1,
                    timestamp: Date.now(),
                    total_weight: 1 * product.weight,
                    total_price: 1 * product.konnect_price,
                    cashback: product?.subcat?.cashback * product?.konnect_price * 0.01,
                    subcat: product.subcat
                }
                newProd.data.push(new_cart)
                newProd.total_amount = newProd.total_amount + product.konnect_price
                newProd.total_item = newProd.data.length
                newProd.total_weight = newProd.total_weight + product.weight
                dispatch(addToCart(newProd))
                setAddToCartButton(false);
                openNotificationWithIcon("success", "Product added to Cart Successfully");
                localStorage.setItem('konnect_cart', JSON.stringify(newProd))
            } else {
                // console.log("already in cart egbon")
                openNotificationWithIcon("success", "Product in cart already");
            }
            setAddToCartButton(false);
        }
    }
    return (
        <div>
            <div>
                <h3 className="tile-header">Trending Products</h3>
                <Swiper
                    spaceBetween={15}
                    breakpoints={shopBreakpoints}>
                    {
                        loadingData ?
                            skeleton.map((skeleton, index) => (
                                <SwiperSlide key={index}>
                                    <div key={index}>
                                        {skeleton}
                                    </div>
                                </SwiperSlide>
                            )) :
                            allProducts.map((product, index) => (
                                <SwiperSlide key={index}>
                                    <div className="product-data-cover" key={index}>
                                        <div className="product-img-header">
                                            <img src={product?.product_image} alt="" />
                                            {!addToCartButton ?
                                                <div
                                                    onClick={() => uploadToCart({ product: product })}
                                                    className="product-plus-icon">
                                                    <PlusIcon />
                                                </div>
                                                :
                                                <div
                                                    className="product-plus-icon">
                                                    <LoadingOutlined style={{ fontSize: '20px' }} />
                                                </div>
                                            }
                                        </div>
                                        {/* <Link to="/detail"> */}
                                        <h5 className="product-title">{product?.product_name}</h5>
                                        <h3 className="product-amount"><span className="currency">NGN</span><NumberFormat
                                            className="new_product_amount"
                                            value={product.konnect_price} displayType={'text'} thousandSeparator={true} /></h3>
                                        <p className="product-desc">Free shipping</p>
                                        {/* </Link> */}
                                    </div>
                                </SwiperSlide>
                            ))
                    }
                    {/* <SwiperSlide key={1}>
                        <div className="product-data-cover">
                            <div className="product-img-header"></div>
                            <h5 className="product-title">Tilda Pure Original Basmati Rice 10 kg</h5>
                            <h3 className="product-amount"><span className="currency">NGN</span>15,000.00</h3>
                            <p className="product-desc">Free shipping</p>
                        </div>
                    </SwiperSlide> */}
                </Swiper>
            </div>
        </div>
    )
}

export default OtherProducts;