import React, { useEffect, useState } from "react";
import { Col, Drawer, Form, Input, Row, Select, notification } from "antd";
import { ReactComponent as Cancel } from "../../assets/images/icons/x.svg";
import map_locationIcon from "../../assets/images/restaurant/map_locationIcon.svg";
import { _add_delivery_address, _get_cities_by_state_code } from "../../common/axios_services";
import RightDrawerTemplate from "../RightDrawerTemplate";

export const CheckoutDrawer = ({ visible, onClose, title }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState( "Lagos"); // State for selected LGA
    const [selectedLGA, setSelectedLGA] = useState(""); // State for selected LGA
    const [allLGAs, setAllLGAs] = useState([]); // State for all LGAs

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message,
        });
    };

    const fetchCities = async (state_code = "LA", keyword = "") => {
        try {
            const uploadedCities = await _get_cities_by_state_code(state_code, keyword);
            console.log("Fetched LGAs:", uploadedCities.data.data); // Debug log
            setAllLGAs(uploadedCities.data.data); // Set the LGAs to state
        } catch (err) {
            console.error("Error fetching LGAs:", err);
            openNotificationWithIcon("error", "Failed to fetch LGAs", err.message);
            setAllLGAs([]); // Reset to an empty array in case of error
        }
    };

    const handleLGAChange = (value) => {
        setSelectedLGA(value); // Update selected LGA
        form.setFieldsValue({ lga: value, city: value }); // Update form values
    };

    // Initialize the form when the drawer is visible
    useEffect(() => {
        if (visible) {
            // Load state and LGA values from localStorage
            const storedState = localStorage.getItem("selectedState") || "LA";
            const storedLGA = localStorage.getItem("selectedLGA") || "";
            const storedCity = localStorage.getItem("selectedCity") || "";

            setState(storedState);
            form.setFieldsValue({
                state: storedState,
                lga: storedLGA,
                city: storedCity,
            });

            // Fetch cities/LGAs based on the stored state
            fetchCities(storedState);
        }
    }, [visible]);

    // Handle form submission
    const handleSubmit = async (values) => {
        setLoading(true);

        const payload = {
            name: values.name,
            phone_code: "+234", // Default to Nigeria's country code
            phone_contact: values.phone_contact,
            email: values.email,
            address: values.address,
            landmark: values.landmark || "",
            state: values.state,
            city: values.city,
            lga: values.lga,
            latitude: String(values.latitude || "0"),
            longitude: String(values.longitude || "0"),
            is_default: true,
        };

        try {
            await _add_delivery_address(payload);
            openNotificationWithIcon("success", "Address added successfully!");
            form.resetFields();
            onClose();
            window.location.reload(); // Reload to reflect changes
        } catch (error) {
            openNotificationWithIcon(
                "error",
                error.response?.data?.message || "Failed to add address"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <RightDrawerTemplate closeDrawer={onClose}
             openDrawer={visible}
             width={720} title={`${title}  delivery address`}>
            <Row justify={"center"} className={"mt-10"}>
                <Col xs={24}>
                    <Form form={form} layout="vertical" onFinish={handleSubmit}>
                        {/* Name Field */}
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{required: true, message: "Please enter your name"}]}
                        >
                            <Input className="py-4 px-3" placeholder="Name"/>
                        </Form.Item>

                        {/* Phone Number */}
                        <Form.Item
                            name="phone_contact"
                            label="Phone number"
                            rules={[{required: true, message: "Please enter your phone number"}]}
                        >
                            <Input className="py-4 px-3" placeholder="Phone Number"/>
                        </Form.Item>

                        {/* Email Field */}
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {type: "email", message: "Please enter a valid email"},
                                {required: true, message: "Please enter your email"},
                            ]}
                        >
                            <Input className="py-4 px-3" placeholder="Email Address"/>
                        </Form.Item>

                        {/* Address Field */}
                        <Form.Item
                            name="address"
                            label="Address"
                            rules={[{required: true, message: "Please enter your address"}]}
                        >
                            <Input
                                className="py-4 px-3"
                                placeholder="Address"
                                suffix={<img src={map_locationIcon} className="w-10 h-10" alt="location icon"/>}
                            />
                        </Form.Item>

                        {/* State Select */}
                        <Form.Item name="state" label="State">
                            <Input className="py-4 px-3" value={state} disabled/>
                        </Form.Item>

                        {/*/!* City Select *!/*/}
                        <Form.Item
                            name="city"
                            label="City"
                            rules={[{required: true, message: "Please select a city"}]}
                        >
                            <Select
                                className="w-full"
                                placeholder="Select City"
                                onChange={(value) => form.setFieldsValue({city: value})}
                            >
                                {allLGAs.map((city) => (
                                    <Select.Option key={city.id} value={city.city_name}>
                                        {city.city_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        {/* LGA Select */}
                        <div className="my-3">
                            <Form.Item
                                name="lga"
                                label="LGA"
                                rules={[{required: true, message: "Please select your LGA"}]}
                            >
                                <Select
                                    value={selectedLGA}
                                    onChange={handleLGAChange}
                                    placeholder="Select LGA"
                                >
                                    {allLGAs.map((lga) => (
                                        <Select.Option key={lga.id} value={lga.city_name}>
                                            {lga.city_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        {/* Submit Button */}
                        <div className="my-3 flex justify-end">
                            <Form.Item>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="bg-[#44843F] text-white rounded-3xl border-2 border-black px-40 my-10 py-3"
                                >
                                    {loading ? "Saving..." : "Save"}
                                </button>
                            </Form.Item>
                        </div>

                    </Form>
                </Col>
            </Row>
        </RightDrawerTemplate>
    );
};
