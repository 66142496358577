import React from 'react';
import NumberFormat from 'react-number-format';

const WalletCard = ({ tierType, displayTitleCheck, userTransferData, majorPageSpinner, walletData, handleTitleCheckChange }) => {
  return (
    <div className='relative py-8 px-10 w-full text-black bg-[#eee] shadow-sm shadow-gray-400 border-2 border-[#258635]  flex flex-col gap-y-10 rounded-3xl'>

      <div className="flex items-center justify-between">
        <button onClick={handleTitleCheckChange} className="py-3 px-5 font-gilroyBold rounded-full text-white bg-[#258635] focus:outline-none ">
          {displayTitleCheck ? "Display Account Number" : "Display Wallet Balance"}
        </button>
        <button className="py-3 px-8 font-gilroyBold rounded-full text-white bg-[#258635] focus:outline-none ">
          Tier {tierType}
        </button>
      </div>

      {displayTitleCheck ? ( 
        <>
          <div className="flex flex-col gap-y-4">
            <p className=' text-black font-gil'>Wallet Balance</p> {/**text-[#D6E6FD] */}
            <div className='flex items-center gap-2 font-gilroyBold' >
              <p className="text-4xl ">NGN</p>
              <p className='text-5xl'>
                {!majorPageSpinner ? (
                  <NumberFormat thousandSeparator={true} prefix={''} className="numeric" displayType={'text'} value={Math.floor(+userTransferData?.available_balance).toFixed(2)} />
                ) : "-"}
              </p>
            </div>
          </div>
          <div className="flex  w-full items-center gap-4">
            <div className="py-2 px-5 rounded-xl flex flex-col gap-y-4 text-white w-1/2 bg-[#359645] focus:outline-none">
              <p className='text-[#D6E6FD] font-gil'>Bonus Balance</p>
              <div className='flex flex-wrap items-center gap-2 font-gilroyBold' >
                <p className='text-[12px]'>₦</p>
                <p className='text-[12px]'>
                  {walletData?.bonus_bal ? (
                    <NumberFormat thousandSeparator={true} prefix={''} className="numeric" displayType={'text'} value={Math.floor(+walletData?.bonus_bal).toFixed(2)} />
                  ) : "0.00"}
                </p>
              </div>
            </div>
            <div className="py-2 px-5 rounded-xl flex flex-col w-1/2 gap-y-4 text-white bg-[#359645] focus:outline-none">
              <p className='text-[#D6E6FD] font-gil'>Lien Balance</p>
              <div className='flex items-center flex-wrap gap-2 font-gilroyBold' >
                <p className='text-[12px]'>₦</p>
                <p className='text-[12px]'>
                  {/* 9000 */}
                  {!majorPageSpinner ? (
                    <NumberFormat thousandSeparator={true} prefix={''} className="numeric" displayType={'text'} value={Math.floor(+walletData?.lien_bal).toFixed(2)} />
                  ) : "0.00"}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-y-4">
            <p className='text-black font-gil'>Account Number</p>
            <div>
              <h3 className='text-5xl text-black font-medium font-gil'>
                {userTransferData.account_number ? userTransferData.account_number : "-"}
              </h3>
            </div> 
          </div>
          <div className="flex flex-col gap-4">
            <div className="py-2 px-5 rounded-xl flex flex-col gap-y-2 text-black bg-slate-50 focus:outline-none">
              <p className='text-black font-gilroyBold text-[13px] '>Account Name</p>
              <h5 className='text-black uppercase font-gilroyBold text-[10px]'>
                {userTransferData.account_number ? userTransferData.account_name : "-"}
              </h5>
            </div>
            <div className="py-2 px-5 rounded-xl flex flex-col gap-y-2 text-black bg-slate-50 focus:outline-none">
              <p className='text-black font-gilroyBold text-[13px]'>Bank Name</p>
              <h5 className='text-black uppercase font-gilroyBold text-[10px]'>
                {userTransferData.account_number ? userTransferData.bank_name : "-"}
              </h5>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WalletCard;
