import axios from 'axios';


const axiosCall = axios.create({
        // baseURL: process.env.REACT_APP_BASE_URL
        baseURL: "https://test-api.konnectbd.com/api/v1",
        // baseURL: "https://prod-api.konnectbd.com/api/v1"
    })

    // console.log(process.env.REACT_APP_BASE_URL)
    // prod
export default axiosCall; 
