
import { Input, Drawer, notification, Spin } from 'antd';
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Jwt_decrypt from '../../common/Jwt_decrypt';
import Jwt_encrypt from '../../common/Jwt_encrypt';
import axios from '../../common/axios_call';

import { _get_profile, _get_profile_from_signin, signIn } from '../../common/axios_services';
import { encrypted_token } from '../../slices/profileSlice';
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left-circle.svg";

import LogoImg from '../../assets/images/logo.png';
import { ReactComponent as LockIcon } from '../../assets/images/icons/lock.svg';
import AppRoutes from '../../common/routes';

const SignInPage = () => {
    const Navigate = useNavigate();
    let params = new URLSearchParams(window?.location?.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false);
    const [redirectUrl,] = useState(params.get('konnectrd') ? params.get('konnectrd') : '');
    const [redir,] = useState(params.get('redir') ? params.get('redir') : '');
    const [referralCode,] = useState(params.get('konnectrfc') ? params.get('konnectrfc') : '');
    const [openAuthResponseModal, setOpenAuthResponseModal] = useState(false);
    const [newAccountModal, setNewAccountModal] = useState(localStorage.getItem('konnectnewaccountcreated') === 'true' ? true : false);
    const [useAccountID, setUseAccountID] = useState(false); // Track sign-in method (default to Email Address)
    const [isPinVisible, setIsPinVisible] = useState(false);



    // Define schemas based on sign-in method
    const signInSchema = useAccountID
        ? yup.object().shape({
            accountID: yup.string().required('Account ID is required'),
            loginPin: yup.string().required('Login Pin is required'),
        })
        : yup.object().shape({
            emailAddress: yup.string().email('Email address is not valid').required('Email address field is required'),
            password: yup.string().min(6).required('Password field is required'),
        });

    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValue: {
            accountID: "",
            loginPin: "",
            emailAddress: "",
            password: "",
        },
        resolver: yupResolver(signInSchema),
    });


    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message
        });
    };

    const fetchUser = async e => {
        try {
            const user_profile = await _get_profile_from_signin(e);
            let userData = user_profile?.data.data;
            
            if (userData.kyc_status === "verified") {
                Navigate(AppRoutes.home);
            } else {
                Navigate(AppRoutes.successful_signup);
            }
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                setErrorMessage(err.response.data.message);
            } else {
                setErrorMessage(err.message);
            }
        }
    }

    const signInUser = async e => {
        setErrorMessage('');
        setFetchingData(true);
        // let { emailAddress, password } = e;  

        const signInData = useAccountID
        ? { email: e.accountID, password: e.loginPin, app: "mobile", device_id: "", device_type: "", role_id: 1 }
        : { email: e.emailAddress, password: e.password, app: "mobile", device_id: "", device_type: "", role_id: 1 };

        try {
            const response = await signIn(signInData);
            // Clear local storage to ensure no leftover data from the previous user
            // localStorage.clear();
            localStorage.setItem('konnect_token', Jwt_encrypt({ token: response?.data?.data?.token }))
            fetchUser(response?.data?.data?.token);
        } catch (err) {
            setFetchingData(false);
            if (err?.response?.status === 401) {
                if (err?.response?.data?.message === "Your email have not been verified. Kindly check your email for confirmation again.") {
                    Navigate(AppRoutes.verify_email);
                    openNotificationWithIcon("error", err?.response?.data?.message);
                } else {
                    setErrorMessage(err?.response?.data?.message);
                }
            } else {
                if (err.response) {
                    setErrorMessage(err?.response?.data?.message);
                } else {
                    setErrorMessage(err?.message);
                }
            }
        } 
    }

    const getUserProfile = async () => {
        try{
          let result = await _get_profile();
        }
        catch(e){
        }
    }

    useEffect(() => {
        getUserProfile();
    })

  


    return (
        <div className="signin-page">
            <div>
                <div className="small-page-title grid-flex">
                    <div onClick={() => navigate("/")}>
                        <ArrowLeftIcon />
                    </div>
                </div>
            </div>
            <div className="contain auth-content">
                <div className="mt_3">
                    <div className="auth-sect-div">
                        <h3 className="auth-sect-title">Welcome to Konnect!</h3>
                        <p>No account yet? <Link className="inner-link" to={AppRoutes.signup}>Click here to create one now.</Link></p>
                    </div>
                    <div className="error-block">{errorMessage && <p className="form-error">{errorMessage}</p>}</div>
                    <form onSubmit={handleSubmit(signInUser)} key={useAccountID ? "accountID" : "email"}>
                        <div className="flex justify-between items-center mb-8">
                            <button type="button" onClick={() => setUseAccountID(!useAccountID)} className="text-white font-gilroyBold text-[13px] py-2 px-5 rounded-xl bg-[#1f6b2b] ">
                                {useAccountID ? 'Sign in using Email Address' : 'Sign in with Account ID'} 
                                <ArrowRightOutlined style={{ marginLeft: "3px" }} />
                            </button>
                        </div>
                        <div>
                            {useAccountID ? (
                                <>
                                    <div className="form_group">
                                        <label htmlFor="accountID">Account ID</label>
                                        <Controller name="accountID" control={control}
                                            render={({ field }) => (
                                                <Input {...field} id="accountID" type='tel' style={{ height: "5rem" }} />
                                            )} />
                                        {errors.accountID && <p className="form-error">{errors.accountID.message}</p>}
                                    </div>
                                    <div className="form_group">
                                        <label htmlFor="loginPin">Login Pin</label>
                                        <Controller name="loginPin" control={control}
                                            render={({ field }) => (
                                                <Input.Password {...field} id="loginPin" inputMode="numeric"  style={{ height: "5rem" }} />
                                            )} />
                                        {errors.loginPin && <p className="form-error">{errors.loginPin.message}</p>}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="form_group">
                                        <label htmlFor="emailAddress">Email Address</label>
                                        <Controller name="emailAddress" control={control}
                                            render={({ field }) => (
                                                <Input {...field} id="emailAddress" style={{ height: "5rem" }} />
                                            )} />
                                        {errors.emailAddress && <p className="form-error">{errors.emailAddress.message}</p>}
                                    </div>
                                    <div className="form_group">
                                        <label htmlFor="password">Password</label>
                                        <Controller name="password" control={control}
                                            render={({ field }) => (
                                                <Input.Password {...field} id="password" style={{ height: "5rem" }} />
                                            )} />
                                        {errors.password && <p className="form-error">{errors.password.message}</p>}
                                    </div>
                                </>
                            )}
                            <div className="my-5 mb-24">
                                <Link className="inner-link" to={ useAccountID ? AppRoutes.reset_pin : AppRoutes.reset_mail}><span>Click here to reset { useAccountID ? "pin": "password" }.</span></Link>
                            </div>
                            <div className="welcome-buttons mt-15">
                                <div className="welcome-buttons-content">
                                    {
                                        !fetchingData ?
                                            <button className="btn-green">Log in to your account</button>
                                            :
                                            <button className="btn-green" disabled><Spin indicator={antIcon} /></button>
                                    }
                                </div>
                                <div className="welcome-buttons-content mt-2">
                                    <Link className="btn-border-green" to="https://konnect.ws/">Continue as a guest</Link>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="bottom-fold-info">
                        <p>Having problems logging in? <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank" className="inner-link">Chat with us</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignInPage;