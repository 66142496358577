import React, { useEffect, useState } from "react";

import { Spin, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";

import 'swiper/css';

import { LoadingOutlined } from "@ant-design/icons";
import FolderIcon from "../../assets/images/illustrations/12_folder.png";
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/icons/mobilenotification.svg";
import FooterDisplay from "../../components/footer";
import { _get_all_orders } from "../../common/axios_services";
import NumberFormat from "react-number-format";
import { DateTime } from "luxon";

import AllAppRoutes from "../../common/routes";
import TopNav from "../../components/top-nav";

const OrdersPage = () => {

    const Navigate = useNavigate();
    const majorSpinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [loadingData, setLoadingData] = useState(false);
    const [page, setPage] = useState(1);
    const [per_page, setPer_page] = useState(10);
    const [total_trans, setTotalTrans] = useState(0);
    const [orderHis, setOrderHis] = useState([]);
    const [deliveredOrder, setDeliveredOrder] = useState([]);
    const [notDeliveredOrder, setNotDeliveredOrder] = useState([]);
    const [currentDispTab, setCurrentDispTab] = useState("expected");

    const [userLogInStatus, setUserLogInStatus] = useState(false);
    const [checkingUserLogInStatus, setCheckingUserLogInStatus] = useState(true);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };

    const allTransOrders = async () => {
        try {
            const allOrders = await _get_all_orders({ page, page_size: per_page });
            // console.log(allOrders)
            if (allOrders.data.status === "success") {
                let delivered = [];
                let not_delivered = [];
                allOrders.data.data.forEach(order => {
                    if (order.delivery_status === "pending") {
                        not_delivered.push(order);
                    } else {
                        delivered.push(order);
                    }
                    setDeliveredOrder(delivered);
                    setNotDeliveredOrder(not_delivered);
                });
                setOrderHis(allOrders.data.data);
                setTotalTrans(allOrders.data.meta.total);
                setLoadingData(false);
            } else {
                openNotificationWithIcon('error', 'An error occurred while loading data');
                setLoadingData(false);
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    Navigate("/signin?redir=review");
                    localStorage.removeItem('konnect_token');
                    openNotificationWithIcon('error', err.response.data.message);
                } else {
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
            setLoadingData(false);
        }
    }

    const jwt = localStorage.konnect_token;

    useEffect(() => {
        if (jwt) {
            setUserLogInStatus(true);
            allTransOrders();
            setCheckingUserLogInStatus(false);
        } else {
            setUserLogInStatus(false);
            setCheckingUserLogInStatus(false);
        }
    }, [])

    return (
        <div>
            {
                checkingUserLogInStatus ?
                    <div>
                        <div className="small-page-title contain">
                            <div className="grid-flex">
                                <div
                                    onClick={() => Navigate(-1)}>
                                    <ArrowLeftIcon />
                                </div>
                                <div></div>
                                <NotificationIcon />
                            </div>
                        </div>
                        <div className="center-content">
                            <div className="center-content-text">
                                <Spin indicator={majorSpinnerIcon} />
                            </div>
                        </div>
                    </div>
                    :
                    userLogInStatus ?
                        <div className="homepage cart-page">
                            <TopNav title="Your Orders" />
                            <div className="display-main-content cart-content mt_3">
                                <div className="contain">
                                    <div className="top-selection-bar">
                                        <div
                                            onClick={() => { setCurrentDispTab("expected") }}
                                            className={`select-bar ${currentDispTab === "expected" ? "active-bar" : ""}`}>
                                            <div className="active-inner-text">
                                                <h4>Expected</h4>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => { setCurrentDispTab("delivered") }}
                                            className={`select-bar ${currentDispTab === "delivered" ? "active-bar" : ""}`}>
                                            <div className="active-inner-text">
                                                <h4>Delivered</h4>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => { setCurrentDispTab("returned") }}
                                            className={`select-bar ${currentDispTab === "returned" ? "active-bar" : ""}`}>
                                            <div className="active-inner-text">
                                                <h4>Returned</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt_4">
                                        {
                                            orderHis.map((history, index) => (
                                                <div className="order-div-box" key={index}>
                                                    <div className="order-div-header">
                                                        <div className="order-div-header-info">
                                                            <div className="order-div-header-image-box">
                                                                <img src={history?.items[0]?.product_image} alt="product image" />
                                                            </div>
                                                            <div>
                                                                <h4>{history?.transaction_ref}</h4>
                                                                <ul>
                                                                    <li>&bull; {history?.total_items} {history?.total_items ? "items" : "item"}</li>
                                                                    <li>&bull; <span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                        className="numeric" displayType={'text'} value={Math.floor(+history?.total_amount).toFixed(2)} /></li>
                                                                </ul>
                                                                <p>&bull; Delivery option: {history?.delivery_opt?.slice(0, 1).toUpperCase()}{history?.delivery_opt?.slice(1)}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="order-date">{DateTime.fromISO(history.created_at).toLocaleString(DateTime.DATE_MED)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="mt_2">
                                                        <div className="">
                                                            <button
                                                                className="btn-border-green">View Order</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <FooterDisplay />
                            </div>
                        </div>
                        :
                        <div>
                            <div className="small-page-title contain">
                                <div className="grid-flex">
                                    <div
                                        onClick={() => Navigate(-1)}>
                                        <ArrowLeftIcon />
                                    </div>
                                    <div></div>
                                    <NotificationIcon />
                                </div>
                            </div>
                            <div className="center-content">
                                <div className="center-content-text">
                                    <img src={FolderIcon} alt="folder" />
                                    <h4>Login Required</h4>
                                    <p>You need to login to access this route. Sign-in to view your successful orders, expected and delivered.</p>
                                    <div>
                                        <div className="">
                                            <Link className="btn-base" to={AllAppRoutes.signin}>Login now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            }
            <FooterDisplay />
        </div >
    )
}

export default OrdersPage;