import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";

import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/icons/arrow-left.svg";
import { ReactComponent as CameraIcon } from "../../../assets/images/icons/camera.svg";

import NumberFormat from 'react-number-format';

import FolderIcon from "../../../assets/images/illustrations/12_folder.png";

import AllAppRoutes from "../../../common/routes";
import { _confirm_user_transfer_pin, _generate_otp, _get_profile, _update_user_type, _user_dashboard_referrals, _verify_otp } from "../../../common/axios_services";
import { Spin, notification } from "antd";
import RequestTransactionCode from "../../../components/profile/transcode";

const SalesReportHistoryPage = props => {

    const Navigate = useNavigate();

    const newAntIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message
        });
    };



    return (
        <div className="detail settings-display">
            <div className="settings-detail-summary">
                <>
                    <div className="profile-first-sect">
                        <div className="top-body-box">
                            <div className="grid-flex">
                                <div onClick={() => props.displayModeControl()} className="notification"><ArrowLeftIcon /></div>
                            </div>
                        </div>
                        <div className="contain">
                            <div className="avatar-blk-cover">
                                {/* <CameraIcon /> */}
                                <ion-icon name="time-outline"></ion-icon>
                            </div>
                            <div>
                                <h4>{props.historyData.fullname}</h4>
                                <div>
                                    <button className="btn-blank">{DateTime.fromISO(props.historyData.timestamp).toLocaleString(DateTime.DATE_HUGE)}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="extra-detail">
                        <div className="contain">
                            <ul>
                                <li className="grid-flex">
                                    <span>Date</span>
                                    <span>{DateTime.fromISO(props.historyData.timestamp).toLocaleString(DateTime.DATE_HUGE)}</span>
                                </li>
                                <li className="grid-flex">
                                    <span>Full name</span>
                                    <span>{props.historyData.fullname}</span>
                                </li>
                                <li className="grid-flex">
                                    <span>Email</span>
                                    <span>{props.historyData.email}</span>
                                </li>
                                <li className="grid-flex">
                                    <span>Address</span>
                                    <span>{props.historyData.new_user_address}</span>
                                </li>
                                <li className="grid-flex">
                                    <span>Phone number</span>
                                    <span>{props.historyData.phone_number}</span>
                                </li>
                                <li className="grid-flex">
                                    <span>SO order amount</span>
                                    <span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                        className="numeric" displayType={'text'} value={Number(props.historyData.so_order_amount).toFixed(2)} /></span>
                                </li>
                                <li className="grid-flex">
                                    <span>SO order number</span>
                                    <span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                        className="numeric" displayType={'text'} value={Number(props.historyData.so_order_no).toFixed(2)} /></span>
                                </li>
                                <li className="grid-flex">
                                    <span>Pending order number</span>
                                    <span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                        className="numeric" displayType={'text'} value={Number(props.historyData.pending_order_income).toFixed(2)} />
                                    </span>
                                </li>
                            </ul>
                            <button onClick={() => props.displayModeControl()} className="btn btn-red">Go Back</button>
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}

export default SalesReportHistoryPage;