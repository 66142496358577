import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fetch: false,
    dataFetched: false,
    token: false
};

export const profileSlice = createSlice({
    name: "profileInfo",
    initialState,
    reducers: {
        fetchData: (state, action) => { state.fetch = action.payload },
        profileData: (state, action) => { state.dataFetched = action.payload },
        encrypted_token: (state, action) => { state.token = action.payload },
    },
});

export const { fetchData, profileData, encrypted_token } = profileSlice.actions;

export default profileSlice.reducer;