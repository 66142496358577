import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";

import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/icons/arrow-left.svg";
import { ReactComponent as CameraIcon } from "../../../assets/images/icons/camera.svg";

import NumberFormat from 'react-number-format';

import FolderIcon from "../../../assets/images/illustrations/12_folder.png";

import AllAppRoutes from "../../../common/routes";
import { _confirm_user_transfer_pin, _generate_otp, _get_profile, _update_user_type, _user_dashboard_referrals, _verify_otp } from "../../../common/axios_services";
import { Spin, notification } from "antd";
import RequestTransactionCode from "../../../components/profile/transcode";

const Wise9jaHistoryDetailPage = props => {

    const Navigate = useNavigate();

    const newAntIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message
        });
    };

    useEffect(() => {
        // console.log(props)
    }, [])

    return (
        <div className="detail settings-display">
            <div className="settings-detail-summary">
                <>
                    <div className="profile-first-sect">
                        <div className="top-body-box">
                            <div className="grid-flex">
                                <div onClick={() => props.displayModeControl()} className="notification"><ArrowLeftIcon /></div>
                            </div>
                        </div>
                        <div className="contain">
                            <div className="avatar-blk-cover">
                                {/* <CameraIcon /> */}
                                <ion-icon name="time-outline"></ion-icon>
                            </div>
                            <div>
                                <h4>Wise9ja Transaction</h4>
                                <div>
                                    <button className="btn-blank">{DateTime.fromISO(props.historyData.created_at).toLocaleString(DateTime.DATE_HUGE)} ; {DateTime.fromISO(props.historyData.created_at).toLocaleString(DateTime.TIME_24_SIMPLE)}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="extra-detail">
                        <div className="contain">
                            <ul>
                                <li className="grid-flex">
                                    <span>Transaction Ref</span>
                                    <span>{props.historyData.referense_no}</span>
                                </li>
                                <li className="grid-flex">
                                    <span>Date subscribed</span>
                                    <span>{DateTime.fromISO(props.historyData.created_at).toLocaleString(DateTime.DATE_HUGE)}</span>
                                </li>
                                {/* <li className="grid-flex">
                                    <span>Charges</span>
                                    <span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                        className="numeric" displayType={'text'} value={Number(props.historyData.charges).toFixed(2)} />
                                    </span>
                                </li> */}
                                <li className="grid-flex">
                                    <span>Subscription amount</span>
                                    <span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                        className="numeric" displayType={'text'} value={Number(props.historyData.amount).toFixed(2)} /></span>
                                </li>
                                <li className="grid-flex">
                                    <span>Status</span>
                                    <span>{props.historyData.status}</span>
                                </li>
                                <li className="grid-flex">
                                    <span>Monthly Payment</span>
                                    <span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                        className="numeric" displayType={'text'} value={Number(props.historyData.monthly_payment).toFixed(2)} />
                                    </span>
                                </li>
                                <li className="grid-flex">
                                    <span>Monthly Commission</span>
                                    <span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                        className="numeric" displayType={'text'} value={Number(props.historyData.monthly_commission).toFixed(2)} />
                                    </span>
                                </li>
                                <li className="grid-flex">
                                    <span>Instance Commission</span>
                                    <span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                        className="numeric" displayType={'text'} value={Number(props.historyData.instance_commission).toFixed(2)} />
                                    </span>
                                </li>

                            </ul>
                            <button onClick={() => props.displayModeControl()} className="btn btn-red">Go Back</button>
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}

export default Wise9jaHistoryDetailPage;