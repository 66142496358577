import React, { useEffect, useState } from "react";
import location_pin from "../assets/images/homepage/location-pin.svg";
import { Select, notification } from "antd";
import { _get_cities_by_state_code } from "../common/axios_services";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

const SearchRestaurant = () => {
  const [all_lgas, set_all_lgas] = useState([]); // Holds LGAs for Lagos
  const [selectedLGA, setSelectedLGA] = useState(""); // Holds the selected LGA
  const navigate = useNavigate(); // Initialize the navigate function

  // Notification handler
  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
      placement: "bottom-right",
    });
  };

  // Fetch LGAs for Lagos using API
  const fetchCities = async (state_code = "LA", keyword = "") => {
    try {
      const uploadedCities = await _get_cities_by_state_code(state_code, keyword);
      set_all_lgas(uploadedCities.data.data); // Set LGAs for Lagos
    } catch (err) {
      set_all_lgas([]);
      if (err.response) {
        openNotificationWithIcon(
          err?.response?.data?.status,
          err?.response?.data?.title,
          err?.response?.data?.message
        );
      } else {
        openNotificationWithIcon("error", "Something went wrong", err.message);
      }
    }
  };

  // Fetch LGAs for Lagos on mount
  useEffect(() => {
    fetchCities();
  }, []);

  // Handle Search Button Click
  const handleSearch = () => {
    if (selectedLGA) {
      navigate(`/restaurant?lga=${encodeURIComponent(selectedLGA)}`); // Navigate to the restaurant page with LGA as a query parameter
    } else {
      openNotificationWithIcon("warning", "Select LGA", "Please select an LGA before searching.");
    }
  };

  return (
    <div className="lg:py-5 w-full lg:flex max-md:flex-col gap-5 max-sm:mt-32 flex justify-end">
      <div className="flex items-center gap-10 text-white py-6 px-10 mt-3 focus:outline-[#44843F] bg-[#9B9B9B] w-full rounded-full">
        <span>
          <img src={location_pin} alt="Location Pin" />
        </span>

        {/* LGA Dropdown */}
        <Select
          placeholder="Select LGA"
          name="lga"
          className="select_search focus:outline-none focus:border-none w-full placeholder:text-white text-white"
          onChange={(value) => setSelectedLGA(value)} // Update selected LGA
        >
          {all_lgas.map((lga) => (
            <Select.Option key={lga.id} value={lga.city_name}>
              {lga.city_name}
            </Select.Option>
          ))}
        </Select>
      </div>

      {/* Search Button */}
      <button
        onClick={handleSearch} // Add click handler
        className="py-5 px-5 rounded-full focus:outline-none bg-[#44843F] max-sm:w-full max-sm:mt-5 font-bold text-white text-2xl"
      >
        Search
      </button>
    </div>
  );
};

export default SearchRestaurant;
