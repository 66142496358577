import React, { useEffect, useState } from "react";

import { Drawer, Input, Select, Spin, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Controller, useForm } from "react-hook-form";

import 'swiper/css';

import NumberFormat from "react-number-format";

import { _check_user_transfer_charges, _complete_bank_transfer, _complete_wallet_transfer, _confirm_user_transfer_pin, _fetch_amount_trans_fee, _fetch_bank_list, _fetch_user_beneficiary_list, _fund_user_wallet, _verify_user_bank_account, _verify_user_from_ref_code } from "../../../common/axios_services";
import RequestTransactionCode from "../../../components/profile/transcode";
import AppRoutes from "../../../common/routes";
import TopNav from "../../../components/top-nav";


const TransferToFriend = () => {

    const Navigate = useNavigate();

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const secAntIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const subAntIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
        });
    };

    // start form handlers

    const [userTransPin, setUserTransPin] = useState('');
    const [pageLoader, setPageLoader] = useState(false);

    // wallet to wallet info
    const [loadingWalletTransFee, setLoadingWalletTransFee] = useState(false);
    const [transferToWalletTransFee, setTransferToWalletTransFee] = useState("");
    const [walletWithdrawalAmount, setWalletWithdrawalAmount] = useState("");
    const [walletWithdrawalAmountError, setWalletWithdrawalAmountError] = useState("");

    const [userBeneficiary, setUserBeneficiary] = useState([]);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState({});
    const [openBeneficiaryModal, setOpenBeneficiaryModal] = useState(false);

    // user transfer
    const navigate = useNavigate();

    // Wallet to Wallet
    const { handleSubmit, control, watch, reset, formState: { errors } } = useForm({});

    const [loadedTransData, setLoadedTransData] = useState(false);
    const [loadUserReferenceCode, setLoadUserReferenceCode] = useState(false);
    const [walletNarration, setWalletTransferNarration] = useState("");
    const [walletTransferAmount, setWalletTransferAmount] = useState("");
    const [userReferenceCode, setUserReferenceCode] = useState("");
    const [userBeneficiaryData, setUserBeneficiaryData] = useState({});
    const [loadingWalletData, setLoadingWalletData] = useState(false);
    const [walletErrorMessage, setWalletErrorMessage] = useState("");
    const [accountErrorMessage, setAccountErrorMessage] = useState("");

    const [transfer_from, setTransferFrom] = useState("wallet");
    const [transfer_to, setTransferTo] = useState("konnect");
    const [displayTransCodeModal, setDisplayTransCodeModal] = useState(false);

    const fetchBankBeneficiaryList = async () => {
        try {
            let userDetails = await _fetch_user_beneficiary_list();
            if (userDetails.data.status === "success") {
                setUserBeneficiary(userDetails.data.data);
            }
        } catch (err) {
        }
    }

    useEffect(() => {
        fetchBankBeneficiaryList();
    }, [])

    const updateTransferAmount = e => {
        let amount = 0;
        if (e.target.value.includes('NGN')) {
            amount = e.target.value.split(' ')[1]?.split(',').join('');
        } else {
            amount = e.target.value;
        }
        setLoadedTransData(amount);
        setWalletTransferAmount(amount);
        setWalletWithdrawalAmount(amount);
    }

    const updateReferenceCode = e => {
        setUserReferenceCode(e.target.value);
    }

    const loadUserDataFromRefCode = async () => {
        setLoadUserReferenceCode(true);
        setAccountErrorMessage('');
        try {
            let userDetails = await _verify_user_from_ref_code(userReferenceCode);
            if (userDetails.data.status === "success") {
                let resp = userDetails.data.data;
                setUserBeneficiaryData({
                    name: resp.account_name,
                    // email_address: resp.email,
                })
            } else {
                openNotificationWithIcon('error', userDetails.data.message);
            }
            setLoadUserReferenceCode(false);
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err?.code === "ERR_NETWORK") {
                    setAccountErrorMessage('An error occurred while checking account number. Please check network and try again');
                } else if (err?.response?.data?.message === "Unauthorized") {
                    setAccountErrorMessage('User Authorization expired. Please log in to continue');
                    navigate('/signin');
                }
                else {
                    setAccountErrorMessage(err?.data?.message.length ? err?.data?.message : err?.response?.data?.message);
                }
            } else {
                setAccountErrorMessage('An error occurred while checking account number. Please try again');
            }
            setLoadUserReferenceCode(false);
            setPageLoader(false);
        }
    }

    const handleWalletTransferForm = e => {
        controlTransCodeModalDisplay();
    }

    useEffect(() => {
        let userRefTimeout = null;
        setUserBeneficiaryData({});
        if (userReferenceCode.length === 10) {
            loadUserDataFromRefCode();
        } else {
            setUserBeneficiaryData({
                ...userBeneficiaryData,
                name: ""
            })
        }
    }, [userReferenceCode]);


    const checkUserTransFeeForTransfer = async () => {
        setLoadingWalletTransFee(true);
        setWalletWithdrawalAmountError("");
        setTransferToWalletTransFee("");
        try {
            const profile = await _fetch_amount_trans_fee({
                settings_id: String(15),
                amount: walletWithdrawalAmount,
                account_number: userReferenceCode
            });
            setTransferToWalletTransFee(profile.data.charges);
            setLoadingWalletTransFee(false);
            setLoadedTransData(true);
        } catch (err) {
            if (err.response.data.message === "Unauthorized") {
                Navigate("/signin");
            }
            setWalletWithdrawalAmountError(err?.response?.data?.message ? err.response.data.message : err.message);
            setLoadingWalletTransFee(false);
        }
    }

    const changeTransferFromOption = e => {
        setTransferFrom(e);
        if (e === "konnect") {
            setTransferTo("konnect");
        } else {
            setTransferTo("wallet")
        }
    }

    const changeTransferToOption = e => {
        setTransferTo(e);
        if (e === "konnect") {
            setTransferFrom("konnect");
        }
    }

    // fetch charges for transfer to friend
    useEffect(() => {
        let userRefTimeout = null;
        if ((walletWithdrawalAmount.length > 2) && (userReferenceCode)) {
            userRefTimeout = setTimeout(checkUserTransFeeForTransfer, 2000);
        }
        return () => clearTimeout(userRefTimeout);
    }, [walletWithdrawalAmount]);

    const completeWalletTransfer = async () => {
        setLoadingWalletData(true);
        setPageLoader(true);
        setWalletErrorMessage('');
        const data = {
            account_type: transfer_from,
            amount: walletWithdrawalAmount,
            // ben_account_type: transfer_to,
            charges: transferToWalletTransFee,
            currency: "NGN",
            narration: "..",
            account_number: userReferenceCode,
            // transfer_opt: "internal"
        }
        try {
            let transferData = await _complete_wallet_transfer(data);
            if (transferData.data.status === "success") {
                openNotificationWithIcon('success', 'Transfer completed successfully');
                let transData = transferData.data.data;
                Navigate(`${AppRoutes.wallet}`);
                // Navigate(`${AppRoutes.action_status_page}?payment_id=${transData.payment_id}&reference_key=${transData.reference}`);
            } else {
                openNotificationWithIcon('error', transferData.data.message);
                setLoadingWalletData(false);
                setPageLoader(false);
            }
        } catch (err) {
            let errMessage = err?.response?.data?.message ? err.response.data.message : err.message;
            if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while completing transfer. Please check network and try again')
            } else {
                openNotificationWithIcon('error', errMessage);
            }
            setLoadingWalletData(false);
            setPageLoader(false);
        }
    }

    const controlTransCodeModalDisplay = () => setDisplayTransCodeModal(!displayTransCodeModal);

    const checkUserPinForWalletTransfer = async e => {
        setLoadingWalletData(true);
        setWalletErrorMessage('');
        controlTransCodeModalDisplay();
        setPageLoader(true);
        try {
            const profile = await _confirm_user_transfer_pin(e);
            if (profile.data.status === "success") {
                completeWalletTransfer();
            } else {
                setLoadingWalletData(false);
                setWalletErrorMessage(profile.data.message);
                setPageLoader(false);
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err?.code === "ERR_NETWORK") {
                    setWalletErrorMessage('An error occurred while checking pin. Please check network and try again');
                } else if (err?.response?.data?.message === "Unauthorized") {
                    setWalletErrorMessage('User Authorization expired. Please log in to continue');
                    navigate('/signin');
                }
                else {
                    setWalletErrorMessage(err?.data?.message.length ? err?.data?.message : err?.response?.data?.message);
                }
            } else {
                setWalletErrorMessage('An error occurred while checking pin. Please try again');
            }
            setLoadingWalletData(false);
            setPageLoader(false);
        }
    }

    const updateUserTransferBeneficiary = () => { }

    return (
        <Spin spinning={pageLoader} indicator={secAntIcon}>
            <div className="display-page">
                <TopNav title="Transfer to a Konnect Wallet" />
                <div className="display-main-content mt_3">
                    <div className="hero-sect">
                        <div className="container">
                            {/* <h3 className="sec-page-title">Transfer to Account</h3> */}
                            <p className="form-explanation">
                                {/* All financial details are stored to PCIDSS level 1 and transmitted via an encrypted 256-bit Https SSL connection. */}
                                Transfer fees may apply to bank and wallet transfer (Tier 1). Next day settlement applies to Tier 1 only.
                            </p>
                            <div>
                                <div className="mt_2">
                                    <div>
                                        {
                                            walletErrorMessage.length ?
                                                <p className="form-error"
                                                    style={{ marginBottom: 30, display: "block" }}>{walletErrorMessage}</p>
                                                : ""
                                        }
                                        <form onSubmit={handleSubmit(handleWalletTransferForm)}>
                                            <div className="form_group">
                                                <div className="form_group">
                                                    <div className="grid-flex" style={{ alignItems: "center" }}>
                                                        <label>Account Number</label>
                                                        <Spin spinning={loadUserReferenceCode} indicator={subAntIcon} />
                                                    </div>
                                                    <div>
                                                        <Input name="referenceCode" onChange={updateReferenceCode} id="referenceCode"
                                                            value={userReferenceCode} style={{ height: "5rem" }} />
                                                        {
                                                            accountErrorMessage.length ?
                                                                <p className="form-error"
                                                                    style={{ display: "block" }}>{accountErrorMessage}</p>
                                                                : ""
                                                        }
                                                    </div>
                                                </div>
                                                <div className="grid-flex">
                                                    <label>Amount</label>
                                                    {/* {
                                                        loadingWalletTransFee ?
                                                            <Spin indicator={subAntIcon} />
                                                            : transferToWalletTransFee ?
                                                                <p><span className="currency">NGN </span><NumberFormat thousandSeparator={true}
                                                                    value={(+transferToWalletTransFee)?.toFixed(2)}
                                                                    className="numeric" displayType="text"
                                                                /></p> : ""
                                                    } */}
                                                </div>
                                                <div>
                                                    <NumberFormat thousandSeparator={true}
                                                        prefix={'NGN '}
                                                        className="numeric"
                                                        onInput={e => updateTransferAmount(e)}
                                                        style={{ height: '5rem', width: '100%' }} />
                                                </div>
                                            </div>
                                            <div className="form_group">
                                                <div className="grid-flex">
                                                    <label>Charges</label>
                                                    {
                                                        loadingWalletTransFee ?
                                                            <Spin indicator={subAntIcon} />
                                                            : ""
                                                    }
                                                </div>
                                                <Input name="charges" disabled value={transferToWalletTransFee ? transferToWalletTransFee : "0.00"}
                                                    id="charges" style={{ height: "5rem" }} />
                                            </div>
                                            <div className="form_group">
                                                <div className="grid-flex" style={{ alignItems: "center" }}>
                                                    <label>Beneficiary Name</label>
                                                </div>
                                                <div>
                                                    <Input name="referenceName" disabled id="referenceName"
                                                        value={userBeneficiaryData.name} style={{ height: "5rem" }} />
                                                </div>
                                            </div>
                                            <div className="welcome-buttons mt_15">
                                                <div className="welcome-buttons-content">
                                                    {
                                                        !loadingWalletData ?
                                                            <button
                                                                disabled={!loadedTransData || (!userBeneficiaryData?.name?.length) ? true : false}
                                                                className="btn-green">Continue</button>
                                                            :
                                                            <button className="btn-green" disabled><Spin indicator={antIcon} /></button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RequestTransactionCode openDrawer={displayTransCodeModal} closeDrawer={controlTransCodeModalDisplay}
                    inputCount={4} actionFunction={checkUserPinForWalletTransfer} stateFunc={setUserTransPin} />
                <Drawer placement="bottom" onCancel={null} className="form-list-modal" footer={null} open={openBeneficiaryModal}>
                    <div className="form-drawer-title">
                        <div>
                            <h3>Select Beneficiary</h3>
                        </div>
                        <div>
                            <div onClick={() => setOpenBeneficiaryModal(false)} className="cancel-bar">
                                <ion-icon name="close-outline"></ion-icon>
                            </div>
                        </div>
                    </div>
                    <div className="mt_4">
                        <form onSubmit={handleSubmit(updateUserTransferBeneficiary)}>
                            <div className="form-group">
                                <label htmlFor="beneficiary">Select Beneficiary</label>
                                <Controller name="beneficiary" control={control}
                                    render={({ field }) => (
                                        <Select {...field} style={{ height: "5rem", display: "block" }}>
                                            {/* {
                                                userBeneficiary.map((beneficiary, index) => (
                                                    <Select.Option key={index}
                                                        value={`${beneficiary.account_name}---${beneficiary.account_number}---${beneficiary.bank_name}`}>{beneficiary?.account_name} &mdash; {beneficiary?.account_number}</Select.Option>
                                                ))
                                            } */}
                                        </Select>
                                    )} />
                            </div>
                            <div className="welcome-buttons mt_5">
                                <div className="welcome-buttons-content">
                                    <div>
                                        <button className="btn-green">Choose Beneficiary</button>
                                    </div>
                                    <div className="">
                                        <button className="btn-cancel">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Drawer>
            </div>
        </Spin>
    )
}

export default TransferToFriend;