import { configureStore } from '@reduxjs/toolkit';
import cartSlice from './slices/cartSlice';
import profileSlice from './slices/profileSlice';
import restaurantCartReducer from './slices/restaurantCartSlice';


export default configureStore({
    reducer: {
        userCart: cartSlice,
        userProfile: profileSlice,
        restaurantCart: restaurantCartReducer,
    },
})