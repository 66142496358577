import React, { useEffect, useState } from "react";

import { Divider, Drawer, Input, Modal, Select, Spin, Tabs, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";

import 'swiper/css';

import Wapp from "../../../assets/images/wapp.jpg";
import NumberFormat from "react-number-format";

import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/icons/arrow-left.svg";
import { _check_user_transfer_charges, _complete_bank_transfer, _complete_wallet_transfer, _confirm_user_transfer_pin, _fetch_amount_trans_fee, _fetch_bank_list, _fetch_user_beneficiary_list, _fund_user_wallet, _verify_user_bank_account, _verify_user_from_ref_code } from "../../../common/axios_services";
import RequestTransactionCode from "../../../components/profile/transcode";
import AppRoutes from "../../../common/routes";
import TopNav from "../../../components/top-nav";


const WithdrawToBank = () => {

    const Navigate = useNavigate();

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const secAntIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const subAntIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message
        });
    };

    // start form handlers
    const { handleSubmit, control, reset, watch, formState: { errors } } = useForm({});
    const { handleSubmit: handleAccountCheckSubmit, control: controlAccountCheck, reset: resetAccountCheck,
        watch: watchAccountCheckSubmit, formState: { errors: errorAccountCheck }, setValue } = useForm({});

    const [currentDispTab, setCurrentDispTab] = useState("wallet");
    const [loadingData, setLoadingData] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(false);
    const [bankData, setBankData] = useState([]);
    const [amountToTransfer, setAmountToTransfer] = useState('');
    const [userTransPin, setUserTransPin] = useState('');
    const [userTransferNarration, setUserTransferNarration] = useState('');
    const [pageLoader, setPageLoader] = useState(false);
    const [loadFindReceiverName, setLoadFindReceiverName] = useState(false);
    const [displayTransCodeModal, setDisplayTransCodeModal] = useState(false);
    const [bankWithdrawalDetails, setBankWithdrawalDetails] = useState({
        ben_account_name: "", ben_bank_name: "", beneficiary_bank_code: "", narration: "", ben_account_number: ""
    })
    const [transferCharges, setTransferCharges] = useState("");

    // wallet to bank info
    const [loadedTransData, setLoadedTransData] = useState(false);
    const [loadingTransFee, setLoadingTransFee] = useState(false);
    const [transferToBankTransFee, setTransferToBankTransFee] = useState("");
    const [bankWithdrawalAmount, setBankWithdrawalAmount] = useState("");
    const [bankWithdrawalAmountError, setBankWithdrawalAmountError] = useState("");

    // wallet to wallet info
    const [loadingWalletTransFee, setLoadingWalletTransFee] = useState(false);
    const [transferToWalletTransFee, setTransferToWalletTransFee] = useState("");
    const [walletWithdrawalAmount, setWalletWithdrawalAmount] = useState("");
    const [walletWithdrawalAmountError, setWalletWithdrawalAmountError] = useState("");
    const [userBeneficiary, setUserBeneficiary] = useState([]);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState({});
    const [openBeneficiaryModal, setOpenBeneficiaryModal] = useState(false);

    // user transfer
    const navigate = useNavigate();

    // Filter `option.label` match the user type `input`
    const filterBankSelectionOption = (input, option) => {
        return (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
    }
    // fetch list of banks
    const fetchBankList = async () => {
        try {
            const bankData = await _fetch_bank_list('NG');
            if (bankData.data.status === "success") {
                setBankData(bankData.data.data);
                // reset();
                setLoadingData(false);
            } else {
                setLoadingData(false);
                openNotificationWithIcon('error', 'An error occurred while fetching bank data. Please reload page to try again')
            }
        } catch (err) {
            if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while fetching bank data. Please check network and try again')
                // setErrorMessage('An error occurred while fetching bank data. Please check network and try again');
            } else {
                openNotificationWithIcon('error', 'An error occurred while fetching bank data. Please reload page to try again')
                // setErrorMessage('An error occurred while fetching bank data. Please reload page to try again');
            }
            setLoadingData(false);
        }
    }

    const fetchBankBeneficiaryList = async () => {
        try {
            let userDetails = await _fetch_user_beneficiary_list();
            if (userDetails.data.status === "success") {
                setUserBeneficiary(userDetails.data.data);
            }
        } catch (err) {
        }
    }

    useEffect(() => {
        fetchBankList();
        fetchBankBeneficiaryList();
    }, [])

    const checkUserTransferDetails = async () => {
        setLoadFindReceiverName(true);
        // setErrorMessage('');
        // setLoadingData(true);
        setBankWithdrawalDetails({
            ...bankWithdrawalDetails,
            ben_account_name: ""
        });
        let dataToVerify = {
            account_number: bankWithdrawalDetails.ben_account_number,
            account_bank: bankWithdrawalDetails.beneficiary_bank_code
        }
        try {
            let userDetails = await _verify_user_bank_account(bankWithdrawalDetails.beneficiary_bank_code, bankWithdrawalDetails.ben_account_number);
            if (userDetails.data.status === "success") {
                setBankWithdrawalDetails({
                    ...bankWithdrawalDetails,
                    ben_account_name: userDetails.data.data.accountName,
                })
            } else {
                openNotificationWithIcon('error', userDetails.data.message);
            }
            setLoadingData(false);
            setLoadFindReceiverName(false);
        } catch (err) {
            if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while fetching bank data. Please check network and try again')
            } else {
                openNotificationWithIcon('error', 'An error occurred while fetching bank data. Please reload page to try again')
            }
            setLoadingData(false);
            setLoadFindReceiverName(false);
        }
    }

    // complete user transfer to bank
    const completeWithdrawToBankTransfer = async e => {
        setLoadingData(true);
        setPageLoader(true);
        // setErrorMessage('');
        let newTransferData = {
            // ben_bank_code: bankWithdrawalDetails.beneficiary_bank_code,
            // ben_bank_name: bankWithdrawalDetails.ben_bank_name,
            // ben_account_type: "bank",
            // ben_account_number: bankWithdrawalDetails.ben_account_number,
            // ben_account_name: bankWithdrawalDetails.ben_account_name,
            // account_type: "wallet",
            // transfer_opt: "external",
            // amount: bankWithdrawalAmount,
            // charges: transferToBankTransFee,
            // debit_currency: "NGN",
            // narration: userTransferNarration
            account_number: bankWithdrawalDetails.ben_account_number,
            amount: bankWithdrawalAmount,
            bank_code: bankWithdrawalDetails.beneficiary_bank_code,
            beneficiary_id: bankWithdrawalDetails.beneficiary_bank_code,
            charges: transferToBankTransFee,
            currency: "NGN",
            narration: ".."
        }
        try {
            let transferData = await _complete_bank_transfer(newTransferData);
            if (transferData.data.status === "success") {
                openNotificationWithIcon('success', 'Transfer completed successfully');
                let transData = transferData.data.data;
                Navigate(`${AppRoutes.wallet}`);
                // Navigate(`${AppRoutes.action_status_page}?payment_id=${transData.product_id}&reference_key=${transData.transaction_ref}`);
            } else {
                // setErrorMessage(transferData.data.message);
                openNotificationWithIcon('error', transferData.data.message);
                setLoadingData(false);
                setPageLoader(false);
            }
        } catch (err) {
            let errMessage = err?.response?.data?.message ? err.response.data.message : err.message;
            if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while completing transfer. Please check network and try again')
                // setErrorMessage('An error occurred while completing transfer. Please check network and try again');
            } else {
                openNotificationWithIcon('error', errMessage);
                // setErrorMessage(errMessage);
            }
            setLoadingData(false);
            setPageLoader(false);
        }
    }

    // confirm user transaction pin
    const checkUserPin = async e => {
        setLoadingData(true);
        controlTransCodeModalDisplay();
        // setErrorMessage('');
        setPageLoader(true);
        try {
            const profile = await _confirm_user_transfer_pin(e);
            if (profile.data.status === "success") {
                // console.log(profile);
                completeWithdrawToBankTransfer();
            } else {
                setLoadingData(false);
                // setErrorMessage(profile.data.message);
                setPageLoader(false);
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err?.code === "ERR_NETWORK") {
                    // setErrorMessage('An error occurred while checking pin. Please check network and try again');
                } else if (err?.response?.data?.message === "Unauthorized") {
                    // setErrorMessage('User Authorization expired. Please log in to continue');
                    navigate('/signin')
                }
                else {
                    // setErrorMessage(err?.data?.message.length ? err?.data?.message : err?.response?.data?.message);
                }
            } else {
                // setErrorMessage('An error occurred while checking pin. Please try again');
            }
            setLoadingData(false);
            setPageLoader(false);
        }
    }

    const setUserWithDrawalAccount = e => {
        if (e?.target?.name) {
            setBankWithdrawalDetails({
                ...bankWithdrawalDetails,
                [e.target.name]: e.target.value
            });
        } else {
            setBankWithdrawalDetails({
                ...bankWithdrawalDetails,
                beneficiary_bank_code: e.split("----")[1],
                ben_bank_name: e.split("----")[0],
                ben_account_name: ""
            });
        }
    }

    const controlTransCodeModalDisplay = () => setDisplayTransCodeModal(!displayTransCodeModal);
    const handleAccountWithdrawalForm = e => {
        setAmountToTransfer(e.amountToTransfer);
        setBankWithdrawalDetails({
            ...bankWithdrawalDetails,
            narration: e.narration
        });
        controlTransCodeModalDisplay();
    }

    useEffect(() => {
        setLoadFindReceiverName(false);
        setLoadingData(false);
        let findRecipient;
        if ((bankWithdrawalDetails?.ben_account_number?.length === 10) && (bankWithdrawalDetails?.ben_bank_name)) {
            // findRecipient = setTimeout(checkUserTransferDetails, 2000);
            checkUserTransferDetails();
        } else {
            setValue("ben_account_name", "");
            setBankWithdrawalDetails({
                ...bankWithdrawalDetails,
                ben_account_name: ""
            });
        }
        // return () => clearTimeout(findRecipient);
    }, [bankWithdrawalDetails.ben_account_number, bankWithdrawalDetails.ben_bank_name])


    // Wallet to Wallet


    const checkUserTransFee = async () => {
        setLoadingTransFee(true);
        setBankWithdrawalAmountError("");
        setTransferToBankTransFee("");
        try {
            const profile = await _fetch_amount_trans_fee({
                settings_id: String(14),
                amount: bankWithdrawalAmount
            });
            setTransferToBankTransFee(profile.data.charges);
            setLoadingTransFee(false);
            setLoadedTransData(true);
        } catch (err) {
            setBankWithdrawalAmountError(err?.response?.data?.message ? err.response.data.message : err.message);
            setLoadingTransFee(false);
        }
    }
    const checkUserTransFeeForTransfer = async () => {
        setLoadingWalletTransFee(true);
        setWalletWithdrawalAmountError("");
        setTransferToWalletTransFee("");
        try {
            const profile = await _fetch_amount_trans_fee({
                settings_id: String(15),
                amount: walletWithdrawalAmount
            });
            setTransferToWalletTransFee(profile.data.charges);
            setLoadingWalletTransFee(false);
        } catch (err) {
            if (err.response.data.message === "Unuthorized") {
                Navigate("/signin");
            }
            setWalletWithdrawalAmountError(err?.response?.data?.message ? err.response.data.message : err.message);
            setLoadingWalletTransFee(false);
        }
    }

    const setUserBankWithdrawalAmount = e => {
        // setBankWithdrawalAmount(e.target.value);
        let amount = 0;
        if (e.target.value.includes('NGN')) {
            amount = e.target.value.split(' ')[1]?.split(',').join('');
        } else {
            amount = e.target.value;
        }
        setLoadedTransData(false);
        setBankWithdrawalAmount(amount);
    }

    // fetch charges for withdraw to bank
    useEffect(() => {
        let userRefTimeout = null;
        if (bankWithdrawalAmount.length > 2) {
            userRefTimeout = setTimeout(checkUserTransFee, 2000);
        }
        return () => clearTimeout(userRefTimeout);
    }, [bankWithdrawalAmount]);

    const updateUserTransferBeneficiary = e => {
        let singleBankData = bankData.find((bank) => {
            if (bank.name === e.beneficiary.split("---")[2]) {
                return bank
            }
        })
        // ben_account_number
        setValue("ben_account_number", e.beneficiary.split("---")[1]);
        setValue("ben_account_name", e.beneficiary.split("---")[0]);
        setValue("ben_bank_name", `${e?.beneficiary?.split("---")[2]}----${singleBankData?.nipCode}`);
        setOpenBeneficiaryModal(false);

        setBankWithdrawalDetails({
            // ...bankWithdrawalDetails,
            beneficiary_bank_code: singleBankData?.nipCode,
            ben_bank_name: e.beneficiary.split("---")[2],
            ben_account_name: e.beneficiary.split("---")[0],
            ben_account_number: e.beneficiary.split("---")[1],
        });
    }

    // setBankWithdrawalDetails({
    //     ...bankWithdrawalDetails,
    //     beneficiary_bank_code: e.split("----")[1],
    //     ben_bank_name: e.split("----")[0],
    //     ben_account_name: ""
    // });

    return (
        <Spin spinning={pageLoader} indicator={secAntIcon}>
            <div className="display-page">
                <TopNav title="Withdraw to a Bank Account" />
                <div className="display-main-content mt_3">
                    <div className="hero-sect">
                        <div className="container">
                            {/* <h3 className="sec-page-title">Transfer to Account</h3> */}
                            <p className="form-explanation">
                                Transfer fees may apply to bank and wallet transfer (Tier 1). Next day settlement applies to Tier 1 only.
                            </p>
                            <div>
                                <div className="mt_2">
                                    <div>
                                        <form onSubmit={handleAccountCheckSubmit(handleAccountWithdrawalForm)}>
                                            <div className="form_group">
                                                <div className="grid-flex">
                                                    <label>Amount</label>
                                                    {/* {
                                                        loadingTransFee ?
                                                            <Spin indicator={subAntIcon} />
                                                            : ""
                                                    } */}
                                                </div>
                                                <Controller name="amountToTransfer" control={controlAccountCheck}
                                                    render={({ field }) => (
                                                        <NumberFormat thousandSeparator={true}
                                                            prefix={'NGN '}
                                                            className="numeric"
                                                            onInput={e => setUserBankWithdrawalAmount(e)}
                                                            {...field} style={{ height: '5rem', width: '100%' }} />
                                                        // <Input name="amountToTransfer" {...field} onChange={} id="amountToTransfer"
                                                        //     style={{ height: "5rem" }} />
                                                    )} />
                                                {/* {
                                                    loadingTransFee ?
                                                        ""
                                                        : transferToBankTransFee ?
                                                            <p><span className="currency">NGN </span><NumberFormat thousandSeparator={true}
                                                                value={(+transferToBankTransFee)?.toFixed(2)}
                                                                className="numeric" displayType="text"
                                                            /></p> : ""
                                                } */}
                                                {bankWithdrawalAmountError.length ? <p className="form-error">{bankWithdrawalAmountError}</p> : ""}
                                            </div>
                                            <div className="form_group">
                                                <div className="grid-flex">
                                                    <label>Charges</label>
                                                    {
                                                        loadingTransFee ?
                                                            <Spin indicator={subAntIcon} />
                                                            : ""
                                                    }
                                                </div>
                                                <Controller name="charges" control={controlAccountCheck}
                                                    render={({ field }) => (
                                                        <Input name="charges" disabled value={transferToBankTransFee ? transferToBankTransFee : "0.00"}
                                                            id="charges" style={{ height: "5rem" }} />
                                                    )} />

                                                {/* {
                                                    loadingTransFee ?
                                                        ""
                                                        : transferToBankTransFee ?
                                                            <p><span className="currency">NGN </span><NumberFormat thousandSeparator={true}
                                                                value={(+transferToBankTransFee)?.toFixed(2)}
                                                                className="numeric" displayType="text"
                                                            /></p> : ""
                                                } */}
                                            </div>
                                            <div className="form_group">
                                                <div className="grid-flex">
                                                    <label>Bank Name</label>
                                                    <div>
                                                        <button type="button" onClick={() => setOpenBeneficiaryModal(true)} className="btn btn-blank">Add Beneficiary</button>
                                                    </div>
                                                </div>
                                                <Controller name="ben_bank_name" control={controlAccountCheck} defaultValue=""
                                                    render={({ field }) => (
                                                        <Select
                                                            showSearch
                                                            style={{ display: "block", width: "100%", height: "5rem" }}
                                                            placeholder="" onChange={setUserWithDrawalAccount}
                                                            optionFilterProp="children" value={`${bankWithdrawalDetails?.ben_bank_name}----${bankWithdrawalDetails?.beneficiary_bank_code}`}
                                                            filterOption={filterBankSelectionOption}>
                                                            {bankData.map((bank, index) => (
                                                                <Select.Option value={`${bank?.name}----${bank.nipCode}`} key={index}>
                                                                    {bank.name}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    )} />
                                            </div>
                                            <div className="form_group">
                                                <div className="grid-flex" style={{ alignItems: "center" }}>
                                                    <label>Account Number</label>
                                                    <Spin spinning={loadFindReceiverName} indicator={subAntIcon} />
                                                </div>
                                                <Controller name="ben_account_number" control={controlAccountCheck}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Input name="ben_account_number" onChange={setUserWithDrawalAccount} id="ben_account_number"
                                                            value={bankWithdrawalDetails?.ben_account_number} style={{ height: "5rem" }} />
                                                    )} />
                                                {/* {
                                                    bankWithdrawalDetails.ben_account_name ? <p className="body-text success-text">{bankWithdrawalDetails.ben_account_name}</p> : ""
                                                } */}
                                            </div>
                                            <div className="form_group">
                                                <label>Account Name</label>
                                                <Controller name="ben_account_name" control={controlAccountCheck} defaultValue={bankWithdrawalDetails.ben_account_name}
                                                    render={({ field }) => (
                                                        <Input name="ben_account_name" disabled value={bankWithdrawalDetails.ben_account_name} id="ben_account_name"
                                                            style={{ height: "5rem" }} />
                                                    )} />
                                            </div>
                                            <div className="welcome-buttons mt_15">
                                                <div className="welcome-buttons-content">
                                                    {
                                                        !loadingData ?
                                                            <button
                                                                // disabled={!loadedTransData ? true : false} 
                                                                disabled={!loadedTransData || (!bankWithdrawalDetails?.ben_account_name?.length) || (!bankWithdrawalAmount.length) ? true : false}
                                                                className="btn-green">Continue</button>
                                                            :
                                                            <button className="btn-green" disabled><Spin indicator={antIcon} /></button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RequestTransactionCode openDrawer={displayTransCodeModal} closeDrawer={controlTransCodeModalDisplay}
                    inputCount={4} actionFunction={checkUserPin} stateFunc={setUserTransPin} />
                <Drawer placement="bottom" onCancel={null} className="form-list-modal" footer={null} open={openBeneficiaryModal}>
                    {
                        !userBeneficiary.length ?
                            <div className="inline-status-box" style={{ textAlign: "center" }}>
                                <div className="hero-sect">
                                    <div className="container">
                                        <div className="status-circle">
                                            {/* <img src={ConfettiImg} alt="confetti" /> */}
                                        </div>
                                        <h4>No Beneficiary Yet!</h4>
                                        <p className="form-explanation">
                                            You have not saved any user as a beneficiary. Please transfer to an account to get started.
                                            {/* We require your data to complete your signup process so you can start saving money while you shop and
                                            earn extra cash monthly. */}
                                        </p>
                                        <div>
                                            <button onClick={() => setOpenBeneficiaryModal(false)} className="btn btn-red" target="_blank" href="https://konnect.ws/signin">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="form-drawer-title">
                                    <div>
                                        <h3>Select Beneficiary</h3>
                                    </div>
                                    <div>
                                        <div onClick={() => setOpenBeneficiaryModal(false)} className="cancel-bar">
                                            <ion-icon name="close-outline"></ion-icon>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt_4">
                                    <form onSubmit={handleSubmit(updateUserTransferBeneficiary)}>
                                        <div className="form-group">
                                            <label htmlFor="beneficiary">Select Beneficiary</label>
                                            <Controller name="beneficiary" control={control}
                                                render={({ field }) => (
                                                    <Select {...field} style={{ height: "5rem", display: "block" }}>
                                                        {
                                                            userBeneficiary.map((beneficiary, index) => (
                                                                <Select.Option key={index}
                                                                    value={`${beneficiary.account_name}---${beneficiary.account_number}---${beneficiary.bank_name}`}>{beneficiary?.account_name} &mdash; {beneficiary?.account_number}</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                )} />
                                        </div>
                                        <div className="welcome-buttons mt_5">
                                            <div className="welcome-buttons-content">
                                                <div>
                                                    <button className="btn-green">Choose Beneficiary</button>
                                                </div>
                                                <div className="">
                                                    <button className="btn-cancel">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                    }
                </Drawer>
            </div>
        </Spin>
    )
}

export default WithdrawToBank;