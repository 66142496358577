import React, { useEffect, useState } from "react";

import { Spin, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";

import 'swiper/css';

import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/icons/arrow-left.svg";
import FooterDisplay from "../../../components/footer";
import { fetchData, profileData } from "../../../slices/profileSlice";
import { _get_all_orders, _get_profile } from "../../../common/axios_services";
import { DateTime } from "luxon";
import { ReactComponent as CameraIcon } from "../../../assets/images/icons/camera.svg";
import { useDispatch } from "react-redux";

const E_IDPage = () => {

    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const newAntIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

    const [userData, setUserData] = useState({});
    const [loadingSpinner, setLoadingSpinner] = useState(true);
    const [checkingUserLogInStatus, setCheckingUserLogInStatus] = useState(true);

    const [jwt] = useState(localStorage.getItem("konnect_token"));

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };

    const fetchUser = async () => {
        setLoadingSpinner(true);
        try {
            const user_profile = await _get_profile();
            dispatch(fetchData(true));
            dispatch(profileData(user_profile?.data?.data));
            let userData = user_profile?.data.data;
            setUserData(userData);
            setLoadingSpinner(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate(`/signin`)
                }
                dispatch(profileData(false))
                dispatch(fetchData(false))
            } else {
            }
            setLoadingSpinner(false);
            // console.log("yo")
        }
    }
    useEffect(() => {
        if (jwt) {
            fetchUser();
        } else {
            setLoadingSpinner(false);
        }
    }, [])

    return (
        <Spin spinning={loadingSpinner} indicator={newAntIcon}>
            <div>
                <div className="homepage cart-page eid">
                    <div className="profile-first-sect">
                        <div className="top-body-box">
                            <div className="grid-flex">
                                <div
                                    onClick={() => Navigate(-1)}>
                                    <ArrowLeftIcon />
                                </div>
                            </div>
                        </div>
                        <div className="contain">
                            <div className="avatar-blk-cover">
                                {!userData?.image?.length ? (
                                    <CameraIcon />
                                ) : (
                                    <img
                                        src={userData.image}
                                        alt={`${userData.first_name} profile picture`}
                                    />
                                )}
                                {/* <CameraIcon /> */}
                            </div>
                        </div>
                    </div>
                    <div className="display-main-content e-id-box cart-content">
                        <div className="contain">
                            <div className="contain">
                                <div className="">
                                    <div className="form-content">
                                        <p>First name</p>
                                        <h5>{userData.first_name}</h5>
                                    </div>
                                    <div className="form-content">
                                        <p>Last name</p>
                                        <h5>{userData.last_name}</h5>
                                    </div>
                                    <div className="form-content">
                                        <p>Email address</p>
                                        <h5>{userData.email}</h5>
                                    </div>
                                    <div className="grid-2">
                                        <div className="form-content">
                                            <p>Telephone</p>
                                            <h5>+{userData.phone_number}</h5>
                                        </div>
                                        <div className="form-content">
                                            <p>Date joined</p>
                                            <h5>{DateTime.fromISO(userData.created_at).toLocaleString(DateTime.DATE_MED)}</h5>
                                        </div>
                                    </div>
                                    <div className="form-content">
                                        <p>Referral Code (User ID)</p>
                                        <h5>{userData.ref_code}</h5>
                                    </div>
                                    <div style={{ textAlign: "center", marginTop: "8rem" }}>
                                        <p style={{ lineHeight: "1.5" }}>This platform is managed by Consukon Limited.</p>
                                        <p>1 Adekunle Owobiyi close, Ikeja, Lagos, Nigeria.</p>
                                        <ul style={{ display: "fle" }}>
                                            <li style={{ listStyle: "none" }}>
                                                <a style={{ fontSize: "1.3rem" }} href="tel:08073376943" target="_blank">Call: 0807 337 6943</a>
                                            </li>
                                            <li style={{ listStyle: "none" }}>
                                                <a href="https://wa.me/message/VDOXKM2HBTWXF1" target="_blank" className="btn btn-blank" style={{ color: "#000", fontSize: "1.3rem", borderBottom: "2px solid #000" }}>Click here to chat with us on Whatsapp</a>
                                            </li>
                                        </ul>
                                        {/* <img src={QrCodeImg} className="qrcode" alt="qr code" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <FooterDisplay /> */}
                    </div>
                </div>
                <FooterDisplay />
            </div>
        </Spin>
    )
}

export default E_IDPage;