import React, { useEffect, useState } from "react";

import { Input, Select, Spin, Tabs, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";

import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";
import TopNav from "../components/top-nav";
import { _send_user_complaint } from "../common/axios_services";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ContactUsPage = () => {

    const Navigate = useNavigate();
    const [loadingData, setLoadingData] = useState(false);
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const { Option } = Select; // Make sure this line is included
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
        });
    };

    // start form handlers
    const digitsOnly = (value) => /^\d+$/.test(value);

    const contactValidator = yup.object().shape({
        first_name: yup.string().required('First name field is required'),
        last_name: yup.string().required('Last name field is required'),
        email: yup.string().email('Email address is not valid').required('Email address field is required'),
        phone_number: yup.string()
            .min(9, 'Please enter a valid phone number')
            .required('Phone number field is required')
            .test('Digits only', 'The field should have digits only', digitsOnly),
        message: yup.string().required('Please let us know how we can help you'),
    })
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(contactValidator)
    });

    const completeContactUs = async e => {
        try {
            const contact = await _send_user_complaint(e);
            reset();
            openNotificationWithIcon('success', 'Thank you for reaching out to us. We will get back to you soon');
        } catch (err) {
            if (err?.response?.data?.message) {
                openNotificationWithIcon('error', err?.response?.data?.message);
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while completing request. Please check network and try again.');
            } else {
                openNotificationWithIcon('error', 'An error occurred while completing request. Please try again.');
            }
            setLoadingData(false);
        }
    }

    return (
        <div className="display-page">
            <TopNav title="Reach out to us" />
            <div className="display-main-content mt_2">
                <div className="hero-sect">
                    <div className="container">
                        <div className={"business_info"}>
                            <div
                                className={" w-full h-1/2 p-6 bg-blend-soft-light shadow-green-200 shadow-md border-2 border-green-200 "}>
                                <p className="text-2xl py-3"><span className={"font-bold"}>Business name: </span>Consukon
                                    Limited</p>
                                <p className="text-2xl py-3"><span className={"font-bold"}>Business address: </span>1
                                    Adekunle Owobiyi Close,
                                    Ogba Ikeja, Lagos, Nigeria</p>
                                <p className="text-2xl py-3"><span className={"font-bold"}>Telephone: </span>08073376943
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="mt_2">
                                <form onSubmit={handleSubmit(completeContactUs)}>
                                    <div className="form-flex-2">
                                        <div className="form_group">
                                            <label>First name</label>
                                            <Controller name="first_name" control={control}
                                                        render={({field}) => (
                                                            <Input name="first_name" {...field} id="first_name"
                                                                   style={{height: "5rem"}}/>
                                                        )}/>
                                            {errors.first_name &&
                                                <p className="form-error">{errors.first_name.message}</p>}
                                        </div>
                                        <div className="form_group">
                                            <label>Last name</label>
                                            <Controller name="last_name" control={control}
                                                        render={({field}) => (
                                                            <Input name="last_name" {...field} id="last_name"
                                                                   style={{height: "5rem"}}/>
                                                        )}/>
                                            {errors.last_name &&
                                                <p className="form-error">{errors.last_name.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label>Email address</label>
                                        <Controller name="email" control={control}
                                                    render={({field}) => (
                                                        <Input name="email" type="email" {...field} id="email"
                                                               style={{height: "5rem"}}/>
                                                    )}/>
                                        {errors.email && <p className="form-error">{errors.email.message}</p>}
                                    </div>
                                    <div className="form_group">
                                        <label>Phone number</label>
                                        <Controller name="phone_number" control={control}
                                                    render={({field}) => (
                                                        <Input name="phone_number" {...field} id="phone_number"
                                                               style={{height: "5rem"}}/>
                                                    )}/>
                                        {errors.phone_number &&
                                            <p className="form-error">{errors.phone_number.message}</p>}
                                    </div>
                                    <div className="form_group">
                                        <label>Issue to manage</label>
                                        <Controller name="issue_type" control={control}
                                                    render={({field}) => (
                                                        <Select {...field} id="issue_type" className="custom-select"  style={{width: "100%"}}>
                                                            <Option value="close_account">Close my account</Option>
                                                            <Option value="wallet_issue">Wallet issues</Option>
                                                            <Option value="restaurant_issue">Restaurant issue</Option>
                                                            <Option value="other">Other issues</Option>
                                                        </Select>
                                                    )}/>
                                        {errors.issue_type && <p className="form-error">{errors.issue_type.message}</p>}
                                    </div>
                                    <div className="form_group">
                                        <label>Message</label>
                                        <Controller name="message" control={control}
                                                    render={({field}) => (
                                                        <Input.TextArea rows={3} name="message" {...field}
                                                                        id="message"/>
                                                    )}/>
                                        {errors.message && <p className="form-error">{errors.message.message}</p>}
                                    </div>
                                    <div className="welcome-buttons mt_20">
                                        <div className="welcome-buttons-content">
                                            {
                                                !loadingData ?
                                                    <button className="btn-green">Send a message</button>
                                                    :
                                                    <button className="btn-green" disabled><Spin indicator={antIcon}/>
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <FooterDisplay /> */}
        </div>
    )
}

export default ContactUsPage;