import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";

import Diamonds from "../assets/images/illustrations/Diamonds.png";
import _404 from "../assets/images/illustrations/404.png";
import Calendar from "../assets/images/illustrations/Calendar.png";

import VendorImg from "../assets/images/signup/vendor.PNG";
import ExtraImg from "../assets/images/signup/extra.PNG";
import ShopImg from "../assets/images/signup/shop.PNG";


import { Link } from "react-router-dom";

const WelcomePage = () => {
    return (
    <div className="welcome-display" >
        <div className="welcome-box" >
            <div className="welcome-central-message" >
                <div className="welcome-spacing">
                    <img src={ExtraImg} className="signup_img" alt="wise9ja" />
                    <h3 >Think Food, Think Konnect </h3>
                    <h3>Get 30% discount </h3>
                    

                </div > 
            </div>
        </div>
        <div className="welcome-buttons">
            <div className="welcome-buttons-content">
                <Link className="btn-green" to="/signin"> Log in to your account </Link>
                <Link className="border-green" to="/signup"> Create a free account </Link>
            </div>
        </div>
    </div>
    )
}
export default WelcomePage;