
import React, { useState, useEffect } from "react";

import { Input, Divider, Drawer, notification, Spin, Select } from "antd";
import { DateTime } from 'luxon';

import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";

import { ReactComponent as NotificationIcon } from "../../../assets/images/icons/mobilenotification.svg";

import { _complete_wise9ja_payment, _confirm_user_transfer_pin, _fetch_user_wallet_data, _fetch_wise9ja_plans, _get_all_account_details, _get_all_wise9ja, _get_profile, _wise9ja_topup } from '../../../common/axios_services';

import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/icons/arrow-left.svg";
import { Controller, useForm } from "react-hook-form";
import BasicOTPComponent from "../../../components/otpform";
import AppRoutes from "../../../common/routes";
import TopNav from "../../../components/top-nav";
import RequestTransactionCode from "../../../components/profile/transcode";

const StartEarningPage = () => {

    const Navigate = useNavigate();
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
        });
    };

    // transaction code confirmation starts
    const { handleSubmit: handleTransactionCodeForm, control: controlTransactionCodeConfirmation,
        formState: { errors: errorsTransCodeConfirmation } } = useForm({});

    const antIcon = <LoadingOutlined style={{ fontSize: 12, color: '#fff' }} spin />;
    const majorSpinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const dataLoaderIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />;

    const [errorMessage, setErrorMessage] = useState("");
    const [userTransCode, setUserTransCode] = useState("");
    const [loadingData, setLoadingData] = useState(false);
    const [verifiedOtp, setVerifiedOtp] = useState(false);
    const [currentDispTab, setCurrentDispTab] = useState("wallet");
    const [transCodeDrawer, setTransCodeDrawer] = useState(false);
    const [majorPageSpinner, setMajorPageSpinner] = useState(false);
    const [displayWise9jaDataLoader, setDisplayWise9jaDataLoader] = useState(false);
    const [, setUserTransPin] = useState('');
    const [displayTransCodeModal, setDisplayTransCodeModal] = useState(false);


    const completeWise9jaPayment = async e => {
        setMajorPageSpinner(true);
        let paymentInfo = {
            plan_id: selectedPeriod.id,
            description: selectedPeriod.description,
            amount: amount,
            currency: "NGN"
        }
        try {
            let userLoyaltyData = await _complete_wise9ja_payment(paymentInfo);
            Navigate("/wise9ja");
            openNotificationWithIcon(userLoyaltyData?.data?.status, userLoyaltyData?.data?.message);
        } catch (err) {
            if (err?.response?.data?.message) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.message)
                setErrMsg(err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err?.message)
                setErrMsg(err.message);
            }
            setLoadingData(false);
            setMajorPageSpinner(false);
        }
    }

    const verifyUserTransactionPin = async e => {
        setErrorMessage('');
        setLoadingData(true);
        controlTransCodeModalDisplay();
        setMajorPageSpinner(true);
        try {
            const profile = await _confirm_user_transfer_pin(e);
            if (profile.data.status === "success") {
                setVerifiedOtp(true);
                completeWise9jaPayment();
            } else {
                setLoadingData(false);
                setErrorMessage(profile.data.message);
                setMajorPageSpinner(false);
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err?.code === "ERR_NETWORK") {
                    setErrorMessage('An error occurred while checking pin. Please check network and try again');
                    openNotificationWithIcon('error', err?.message);
                } else if (err?.response?.data?.message === "Unauthorized") {
                    setErrorMessage('User Authorization expired. Please log in to continue');
                    openNotificationWithIcon('error', err?.message)
                    Navigate('/signin');
                }
                else {
                    setErrorMessage(err?.data?.message.length ? err?.data?.message : err?.response?.data?.message);
                    openNotificationWithIcon('error', err?.data?.message.length ? err?.data?.message : err?.response?.data?.message)
                }
            } else {
                setErrorMessage('An error occurred while checking pin. Please try again');
                openNotificationWithIcon('error', "An error occurred while checking pin. Please try again")
            }
            setLoadingData(false);
        }
        setErrorMessage('');
        setLoadingData(false);
        setMajorPageSpinner(false);
    }

    const closeTransactionCodeDrawer = () => {
        setTransCodeDrawer(false);
    }

    // transaction code confirmation ends

    // wise9ja process starts

    let jwt_token = localStorage.getItem('konnect_token');
    const { handleSubmit: handleWise9jaForm, control: controlWise9jaForm } = useForm({});
    const [amount, setAmount] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState({});
    const [wise9jaPeriods, setWise9jaPeriods] = useState([]);
    const [wise9jaPeriodData, setWise9jaPeriodData] = useState([]);
    const [userPeriod, setUserPeriod] = useState('');
    const [wise9jaRespData, setWise9jaRespData] = useState('');
    const [walletData, setWalletData] = useState({});
    const [errMsg, setErrMsg] = useState(false);
    const [userPeriodDateSchedules, setUserPeriodDateSchedules] = useState([]);

    const controlTransCodeModalDisplay = () => setDisplayTransCodeModal(!displayTransCodeModal);

    const fetchSelectedPeriodPlan = async e => {
        if (userPeriod.id && amount) {
            setLoadingData(true);
            let newData = {
                plan_id: userPeriod.id,
                amount
            }
            setDisplayWise9jaDataLoader(true);
            try {
                let getWise9jaInfo = await _fetch_wise9ja_plans(newData);
                if (getWise9jaInfo.data.status === "success") {
                    setWise9jaRespData(getWise9jaInfo.data.data);
                    let dateBox = [];
                    let duration = getWise9jaInfo.data.data.durations;
                    for (let i = 0; i <= +duration; i++) {
                        let currIndex = 30 * i;
                        let newDate = DateTime.now().plus({ days: currIndex }).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' });
                        dateBox.push(newDate);
                    }
                    setUserPeriodDateSchedules(dateBox);
                } else {
                    setErrorMessage(getWise9jaInfo.data.message);
                    openNotificationWithIcon('error', getWise9jaInfo.data.message)
                }
                setLoadingData(false);
                setDisplayWise9jaDataLoader(false);
                // setVerifiedOtp(false)
            } catch (err) {
                if (err?.response?.data?.message) {
                    if (err.response.data.message === "Unauthorized") {
                        localStorage.removeItem('konnect_token');
                        Navigate('/signin');
                    } else {
                        openNotificationWithIcon('error', err?.response?.data?.message);
                        setErrorMessage(err?.response?.data?.message);
                    }
                } else if (err?.code === "ERR_NETWORK") {
                    openNotificationWithIcon('error', 'An error occurred while fetching data. Please check network and try again.');
                    setErrorMessage('An error occurred while fetching data. Please check network and try again.e');
                } else {
                    openNotificationWithIcon('error', 'An error occurred while fetching data. Please reload page to try again.');
                    setErrorMessage('An error occurred while fetching data. Please reload page to try again.w');
                }
                setLoadingData(false);
                setDisplayWise9jaDataLoader(false);
            }
        } else {
            openNotificationWithIcon("error", "Please select a plan period")
        }
    }

    const getWise9ja = async () => {
        try {
            const wise9ja = await _get_all_wise9ja();
            let newArray = [];
            wise9ja.data.data.forEach(wise => {
                newArray[wise.plan_name] = wise;
            })
            setWise9jaPeriods(wise9ja.data.data);
            setWise9jaPeriodData(newArray);
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token');
                    Navigate('/signin');
                } else {
                    openNotificationWithIcon('error', err?.response?.data?.message);
                    setErrorMessage(err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while fetching data. Please check network and try again.');
                setErrorMessage('An error occurred while fetching data. Please check network and try again.e');
            } else {
                openNotificationWithIcon('error', 'An error occurred while fetching data. Please reload page to try again.');
                setErrorMessage('An error occurred while fetching data. Please reload page to try again.w');
            }
            setLoadingData(false);
        }
    }

    useEffect(() => {
        getWise9ja();
    }, []);

    useEffect(() => {
        setWise9jaRespData({});
        if (amount && selectedPeriod.min_amount) {
            if (amount >= selectedPeriod.min_amount && amount <= selectedPeriod.max_amount) {
                let periodPlanId = setTimeout(fetchSelectedPeriodPlan, 2500);
                return () => clearTimeout(periodPlanId);
            }
        }
    }, [amount, selectedPeriod])

    const fetchPeriodData = async e => {
        let selectId = wise9jaPeriodData[e];
        setUserPeriod(selectId);
        // setLoadingData(true);
    }

    const onChangeWise9jaValue = e => {
        let amount = 0;
        if (e.target.value.includes('NGN')) {
            amount = e.target.value.split(' ')[1]?.split(',').join('');
        } else {
            amount = e.target.value;
        }
        setAmount(amount);
    }

    const handleCurrentTransCode = e => {
        setUserTransCode(e);
    }


    return (
        <Spin indicator={majorSpinnerIcon} spinning={majorPageSpinner}>
            <div className="display-page">
                <TopNav title="Start Earning" />
                <div className="display-main-content mt_2">
                    <div className="hero-sect">
                        <div className="container">
                            <p className="form-explanation">
                                Subscribe to Wise9ja and earn upfront cashback. Subscription amount will be debited from your Konnect wallet.
                            </p>
                            <div>
                                <div className="mt_2">
                                    <div>
                                        <form onSubmit={handleWise9jaForm(fetchSelectedPeriodPlan)}>
                                            <div className="form_group">
                                                <div className="grid-flex">
                                                    {

                                                    }
                                                    <label htmlFor="amount" style={{ width: '100%' }}>Amount</label>
                                                    <Spin indicator={dataLoaderIcon} spinning={displayWise9jaDataLoader} />
                                                </div>
                                                <Controller control={controlWise9jaForm} defaultValue="" name="amount"
                                                    render={({ field }) => (
                                                        <NumberFormat thousandSeparator={true}
                                                            prefix={'NGN '}
                                                            className="numeric"
                                                            onInput={e => onChangeWise9jaValue(e)}
                                                            {...field} style={{ height: '5rem', width: '100%' }} />
                                                    )
                                                    } />
                                                {
                                                    amount && ((amount < selectedPeriod.min_amount) || (amount > selectedPeriod.max_amount)) ?
                                                        <p className="form-error">Amount can not be less than <span
                                                            style={{ fontSize: '1.1rem' }}
                                                            className="currency">NGN</span><NumberFormat thousandSeparator={true}
                                                                value={selectedPeriod.min_amount}
                                                                className="numeric" displayType="text"
                                                            /> or more than <span
                                                                style={{ fontSize: '1.1rem' }}
                                                                className="currency">NGN</span><NumberFormat thousandSeparator={true}
                                                                    value={selectedPeriod.max_amount}
                                                                    className="numeric" displayType="text"
                                                            /></p> : ''
                                                }
                                            </div>
                                            <div className="form_group">
                                                <label htmlFor="period" style={{ width: '100%' }}>Period</label>
                                                <Controller control={controlWise9jaForm} defaultValue="" name="period"
                                                    render={({ field }) => (
                                                        <Select id="period"
                                                            onChange={e => {
                                                                setSelectedPeriod(wise9jaPeriodData[e]);
                                                                fetchPeriodData(e);
                                                            }}
                                                            style={{ height: '5rem', display: "block" }}>
                                                            {
                                                                wise9jaPeriods.map((wisePeriods, index) => (
                                                                    <Select.Option
                                                                        value={wisePeriods.plan_name}
                                                                        key={index}>
                                                                        {wisePeriods.plan_name}
                                                                    </Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    )} />
                                            </div>
                                            {
                                                userPeriodDateSchedules.length ?
                                                    <div className="wise9ja-earnings-box">
                                                        <Divider>What you earn into your Konnect wallet</Divider>
                                                        <div className="container">
                                                            <ul>
                                                                <li>We will credit you <b><span className="currency" style={{ fontSize: "1.1rem" }}>NGN </span><NumberFormat thousandSeparator={true}
                                                                    value={Number(wise9jaRespData?.instance_commission).toFixed(2)} className="numeric" displayType="text"
                                                                /></b> today as upfront cashback.</li>
                                                                {
                                                                    wise9jaRespData?.voucher_bonus > 0 ?
                                                                        <li>We will credit you <b><span className="currency" style={{ fontSize: "1.1rem" }}>NGN </span><NumberFormat thousandSeparator={true}
                                                                            value={Number(wise9jaRespData?.voucher_bonus)?.toFixed(2)} className="numeric" displayType="text"
                                                                        /></b> today as extra cash from your bonus account</li>
                                                                        :
                                                                        <li> You missed out on earning an extra cash today due to zero bonus balance.</li>
                                                                }
                                                                <li>We will pay back your subscription amount of <span className="currency" style={{ fontSize: "1.1rem" }}>NGN</span><NumberFormat thousandSeparator={true}
                                                                    value={Number(wise9jaRespData.amount)?.toFixed(2)} className="numeric" displayType="text"
                                                                /> plus quarterly interest and as follows:
                                                                    <ul>
                                                                        <p style={{ lineHeight: 2.5 }}><b><span className="currency" style={{ fontSize: "1.1rem" }}>NGN</span><NumberFormat thousandSeparator={true}
                                                                            value={Number(wise9jaRespData.monthly_payment)?.toFixed(2)} className="numeric" displayType="text"
                                                                        /></b> by {DateTime.now().plus({ month: 3 }).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}</p>
                                                                        <p style={{ lineHeight: 2.5 }}><b><span className="currency" style={{ fontSize: "1.1rem" }}>NGN</span><NumberFormat thousandSeparator={true}
                                                                            value={Number(wise9jaRespData.monthly_payment)?.toFixed(2)} className="numeric" displayType="text"
                                                                        /></b> by {DateTime.now().plus({ month: 6 }).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}</p>
                                                                        <p style={{ lineHeight: 2.5 }}><b><span className="currency" style={{ fontSize: "1.1rem" }}>NGN</span><NumberFormat thousandSeparator={true}
                                                                            value={Number(wise9jaRespData.monthly_payment)?.toFixed(2)} className="numeric" displayType="text"
                                                                        /></b> by {DateTime.now().plus({ month: 9 }).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}</p>
                                                                        <p style={{ lineHeight: 2.5 }}><b><span className="currency" style={{ fontSize: "1.1rem" }}>NGN</span><NumberFormat thousandSeparator={true}
                                                                            value={Number(wise9jaRespData.monthly_payment)?.toFixed(2)} className="numeric" displayType="text"
                                                                        /></b> by {DateTime.now().plus({ month: 12 }).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}</p>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    : ""
                                            }
                                            <div className="welcome-buttons mt_20">
                                                <div className="welcome-buttons-content">
                                                    {
                                                        userPeriodDateSchedules.length ?
                                                            !loadingData ?
                                                                <button type="button"
                                                                    onClick={controlTransCodeModalDisplay}
                                                                    className="btn-green">Continue</button>
                                                                :
                                                                <button className="btn-green" disabled><Spin indicator={antIcon} /></button>
                                                            :
                                                            !loadingData ?
                                                                <button className="btn-green">Continue</button>
                                                                :
                                                                <button className="btn-green" disabled><Spin indicator={antIcon} /></button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <RequestTransactionCode openDrawer={displayTransCodeModal} closeDrawer={controlTransCodeModalDisplay}
                            inputCount={4} actionFunction={verifyUserTransactionPin} stateFunc={setUserTransPin} />
                    </div>
                </div>
            </div >
        </Spin >
    )
}

export default StartEarningPage;