import { Col, Row } from 'antd'
import React from 'react'
import { ImageTray } from './ImageTray'

export const SinglePageContent = () => {
  return (
    <>
      <Row justify={'center'} className='h-full' >
        <Col xs={22} xl={16}>
          <ImageTray />
        </Col>
      </Row>


    </>
  )
}
