
import { Input, notification, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from 'react-router-dom';
import Jwt_encrypt from '../../common/Jwt_encrypt';
import { _get_profile, _get_profile_from_signin, signIn } from '../../common/axios_services';
import AppRoutes from '../../common/routes';

const ResetPin = () => {
    // let params = new URLSearchParams(window?.location?.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false);

    // Define schemas based on sign-in method
    const signInSchema= yup.object().shape({
            emailAddress: yup.string().email('Email address is not valid').required('Email address field is required'),
            password: yup.string().min(6).required('Password field is required'),
        });

    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValue: {
            emailAddress: "",
            password: "",
        },
        resolver: yupResolver(signInSchema),
    });


    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message
        });
    };

    const fetchUser = async e => {
        try {
            const user_profile = await _get_profile_from_signin(e);
            let userData = user_profile?.data.data;

            if (userData.kyc_status === "verified") {
                navigate(AppRoutes.update_login_pin);
            } else {
                navigate(AppRoutes.successful_signup);
            }
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                setErrorMessage(err.response.data.message);
            } else {
                setErrorMessage(err.message);
            }
        }
    }

    const signInUser = async e => {
        setErrorMessage('');
        setFetchingData(true);
        // let { emailAddress, password } = e;

        const signInData ={ email: e.emailAddress, password: e.password, app: "mobile", device_id: "", device_type: "", role_id: 1 };

        try {
            const response = await signIn(signInData);
            // Clear local storage to ensure no leftover data from the previous user
            // localStorage.clear();
            localStorage.setItem('konnect_token', Jwt_encrypt({ token: response?.data?.data?.token }))
            fetchUser(response?.data?.data?.token);
        } catch (err) {
            setFetchingData(false);
            if (err?.response?.status === 401) {
                if (err?.response?.data?.message === "Your email have not been verified. Kindly check your email for confirmation again.") {
                    navigate(AppRoutes.verify_email);
                    openNotificationWithIcon("error", err?.response?.data?.message);
                } else {
                    setErrorMessage(err?.response?.data?.message);
                }
            } else {
                if (err.response) {
                    setErrorMessage(err?.response?.data?.message);
                } else {
                    setErrorMessage(err?.message);
                }
            }
        }
    }

    const getUserProfile = async () => {
        try{
            let result = await _get_profile();
        }
        catch(e){
        }
    }

    useEffect(() => {
        getUserProfile();
    })




    return (
        <div className="signin-page">
            <div>
                <div className="small-page-title grid-flex">
                    <div onClick={() => navigate(-1)}>
                        <ion-icon name="arrow-back-circle"></ion-icon>
                    </div>
                </div>
            </div>
            <div className="contain auth-content">
                <div className="mt_3">
                    <div className="auth-sect-div">
                        <h3 className="auth-sect-title">Amend your login PIN!</h3>
                        <p className='width-85'>
                            Enter the email address & password attributed to your account.
                            We will direct you to reset your login PIN.</p>
                    </div>
                    <div className="error-block">{errorMessage && <p className="form-error">{errorMessage}</p>}</div>
                    <form onSubmit={handleSubmit(signInUser)}>
                        <div>
                            <div className="form_group">
                                <label htmlFor="emailAddress">Email Address</label>
                                <Controller name="emailAddress" control={control}
                                            render={({field}) => (
                                                <Input {...field} id="emailAddress" style={{height: "5rem"}}/>
                                            )}/>
                                {errors.emailAddress &&
                                    <p className="form-error">{errors.emailAddress.message}</p>}
                            </div>
                            <div className="form_group">
                                <label htmlFor="password">Password</label>
                                <Controller name="password" control={control}
                                            render={({field}) => (
                                                <Input.Password {...field} id="password"
                                                                style={{height: "5rem"}}/>
                                            )}/>
                                {errors.password && <p className="form-error">{errors.password.message}</p>}
                            </div>

                            <div className="welcome-buttons mt-15">
                                <div className="welcome-buttons-content">
                                    {
                                        !fetchingData ?
                                            <button className="btn-green">Reset Pin</button>
                                            :
                                            <button className="btn-green" disabled><Spin indicator={antIcon}/></button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default ResetPin;