import { Button, Card } from 'antd';
import React from 'react';
// import restaurant_food from '../assets/images/homepage/restaurant_food.png';
import { useNavigate } from 'react-router-dom';
import placeholderImage from  '../assets/images/homepage/restaurant_food.png';
import oops from "../assets/images/illustrations/oops.jpg"




export const FoodImageArea = ({restaurants, }) => {
    const navigate = useNavigate()
  return (
        // <div className="lg:p-8  ">
      <div className='bg-white pb-36 px-3 py-2 rounded-3xl '>
          <div className="align-center mt-8  my-5">
              <div>
                  <h1 className='text-5xl text-[#000000D9]'>Just for you! </h1>
                  <p className='font-gilroyMedium'
                     style={{fontSize: '1.5rem', fontWeight: "medium", color: "#959595"}}>Based on your frequent
                      orders</p>
              </div>
              <Button type="link">View all</Button>
          </div>
          <div className="max-md:overflow-x-auto md:hidden max-md:flex max-md:space-x-2 max-md:px-2 ">
              {!restaurants || restaurants.length === 0 ?
                  (
                      <div>
                          <img src={oops} width={200} className={"mx-auto"} alt='oops' />
                          <h3>No Restaurant Found in your selected city</h3>
                      </div>
                  ) :
                  restaurants.map((restaurant) => {
                      const kitchenImage =
                          restaurant.kitchen_images && restaurant.kitchen_images.length > 0
                              ? restaurant.kitchen_images[0]?.url || placeholderImage
                              : placeholderImage;

                      return (
                          <Card
                              key={restaurant.id}
                              className="restaurant-card w-[70%] flex-shrink-0"
                              onClick={() => navigate(`/restaurant/${restaurant.id}`)}
                          >
                              <img
                                  src={kitchenImage}
                                  alt={restaurant.name}
                              />

                              <div className="align-center">
                                  <h3 className="font-bold ">{restaurant.name}</h3>
                                  <span className="text-red-500 text-lg">❤️</span>
                              </div>
                              <div className="align-center">
                                  <p style={{fontSize: "12px"}}>{restaurant.min_ready_time} - {restaurant.max_ready_time}</p>
                                  <span className="text-yellow-500">⭐ {restaurant.rating || 'N/A'}</span>
                              </div>
                          </Card>
                      );
              })}


          </div>

      </div>
      // </div>
  )
}
