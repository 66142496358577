import React, { useEffect, useState } from "react";

import { Input, Radio, Select, Spin, Tabs, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";

import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/icons/mobilenotification.svg";

import AssessmentQuestions from "./actions/quiz.json";


const AssessmentPage = () => {

    const Navigate = useNavigate();
    const [loadingData, setLoadingData] = useState(true);
    const [allSection, setAllSection] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [quizComplete, setQuizComplete] = useState(false);
    const [userAnswers, setUserAnswers] = useState([]);
    const [userTotalAnswer, setUserTotalAnswer] = useState(0);
    const [timer, setTimer] = useState(120);

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    useEffect(() => {
        let assessments = AssessmentQuestions;
        setAllSection(assessments);
        setLoadingData(false);
    }, [])

    const handleNextQuestion = () => {
        let currIndex = currentIndex;
        let allSectionData = allSection.length ? allSection : AssessmentQuestions;
        // let sectionLength = allSection[currentSectionIndex].length - 1;
        if (!quizComplete) {
            if (currIndex === (allSectionData[currentSectionIndex].length - 1)) {
                let sectIndex = currentSectionIndex;
                if (sectIndex === (allSectionData.length - 1)) {
                    setQuizComplete(true);
                } else {
                    sectIndex++;
                    setCurrentSectionIndex(sectIndex);
                    setCurrentIndex(0);
                }
            } else {
                currIndex++;
                setCurrentIndex(currIndex);
            }
        } else {
            completeUserAssessment();
        }
        setTimer(120);
    }

    const changeUserAnswer = e => {
        let newArr = userAnswers;
        if (newArr[currentSectionIndex]) {
            newArr[currentSectionIndex].push(e.target.value);
        } else {
            let secArray = [e.target.value];
            newArr.push(secArray);
        }
        // console.log(newArr)
        setUserAnswers(newArr);
    }

    const completeUserAssessment = () => {
        setTimer(null);
        let userAns = userAnswers;
        let secAns = [];
        let totalAns = 0;
        userAns.forEach(ans => {
            for (let i = 0; i < ans.length; i++) {
                totalAns += +ans[i].split(",,,")[1];
            }
            secAns.push(ans);
        })
        setUserTotalAnswer(totalAns);
        setQuizComplete(true);
    }

    useEffect(() => {
        if (typeof timer === "number") {
            let currTime = timer;
            let counterFunction = () => {
                currTime = currTime - 1;
                if (currTime === 0) {
                    setTimer(120);
                    handleNextQuestion();
                } else {
                    setTimer(currTime);
                }
            }
            let timerInterval = setInterval(counterFunction, 500);
            return () => clearInterval(timerInterval);
        }
    }, [timer]);

    return (
        <div className="display-page story-page how-to">
            <div className="small-page-title contain">
                <div className="grid-flex">
                    <div
                        onClick={() => Navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    {/* <h5>Transfer Cash</h5> */}
                    <div></div>
                        <div className="chat">
                            <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank">
                                <ion-icon name="chatbubbles-outline"></ion-icon>
                            </a>
                        </div>
                </div>
            </div>
            <div className="display-main-content mt_2">
                <div className="hero-sect">
                    <div className="container">
                        <h3 className="sec-page-title">To join us, you must complete a 30 minutes assessment</h3>
                        <div>
                            <p>This is a 30 minutes multiple choice assessment. All answers do carry a score
                                and we grade on best answers.</p>
                            <div>
                                <div>
                                    <div className="white-block white-bg mt_3">
                                        <div className="quiz-options">
                                            {
                                                !loadingData ?
                                                    !quizComplete ?
                                                        <React.Fragment>
                                                            {
                                                                currentSectionIndex === 0 ?
                                                                    <div>
                                                                        <h4 className="quiz-section-title">Section 1 - Can we know you more?</h4>
                                                                        <p className="quiz-section-story">Note each question is timed for 2 minutes and the assessment will restart if you sign-out or
                                                                            click the return button</p>
                                                                    </div>
                                                                    : currentSectionIndex === 1 ?
                                                                        <div>
                                                                            <h4 className="quiz-section-title">Section 2 - Your knowledge of ecommerce and social space.</h4>
                                                                            <p className="quiz-section-story">Note each question is timed for 2 minutes and the assessment will restart if you sign-out or
                                                                                click the return button</p>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <h4 className="quiz-section-title">Section 3 - Your knowledge of being a Sales Agent (please read below before you answer)</h4>
                                                                            <p className="quiz-section-story">Konnect is a platform that helps Food Vendors to buy wholesale food stuffs. Food vendors are
                                                                                either those that operates from a venue or do only online delivery. They are categorized as either Tier 1 or Tier 2.</p>
                                                                        </div>
                                                            }
                                                            <p className="timer">{timer}secs</p>
                                                            <div className="grid-flex">
                                                                <h4 className="quiz-question">{allSection[currentSectionIndex][currentIndex]?.question}</h4>
                                                            </div>
                                                            <Radio.Group style={{ width: "100%" }}
                                                                onChange={changeUserAnswer}
                                                            >
                                                                {
                                                                    allSection[currentSectionIndex][currentIndex]?.options?.map((option, index) => (
                                                                        <Radio key={index}
                                                                            value={option}>{option.split(",,,")[0]}</Radio>
                                                                    ))
                                                                }
                                                            </Radio.Group>
                                                            <div className="mt_5">
                                                                {
                                                                    (currentSectionIndex === (allSection.length - 1)) && (currentIndex === (allSection[currentSectionIndex].length - 1)) ?
                                                                        <button onClick={completeUserAssessment} className="btn-base">Submit Quiz</button>
                                                                        :
                                                                        <button onClick={handleNextQuestion} className="btn-base">Next Question</button>
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <div>
                                                            <div className="result-tab">
                                                                <h4>{userTotalAnswer}</h4>
                                                                <h5 style={{ textAlign: "left" }}>Next step is to do the following</h5>
                                                                <ul>
                                                                    <li>
                                                                        <span>1</span>
                                                                        <p>Check your email for a mail from us</p>
                                                                    </li>
                                                                    <li>
                                                                        <span>2</span>
                                                                        <p>
                                                                            Add or update your profile picture with your recent picture.
                                                                        </p>
                                                                    </li>
                                                                    <li>
                                                                        <span>3</span>
                                                                        <p>
                                                                            Check that we can contact you via WhatsApp using your mobile number.
                                                                        </p>
                                                                    </li>
                                                                </ul>
                                                                <Link to="/settings" className="btn-base">Go Home</Link>
                                                            </div>
                                                        </div>
                                                    : ""
                                            }
                                        </div>
                                        <div className="mt_3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <FooterDisplay /> */}
            </div>
        </div >
    )
}

export default AssessmentPage;