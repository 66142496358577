import "../assets/css/mini.css";

import React, { useEffect, useState } from "react";

import { Divider, notification, Spin, Dropdown } from "antd";
import 'swiper/css';

import { Link, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { DateTime } from 'luxon';

import FolderIcon from "../assets/images/illustrations/12_folder.png";

import { ReactComponent as PlusIcon } from "../assets/images/icons/plus.svg";
import { ReactComponent as GiftIcon } from "../assets/images/icons/gift.svg";
import { ReactComponent as MoveIcon } from "../assets/images/icons/move.svg";
import { ReactComponent as DownloadIcon } from "../assets/images/icons/download.svg";
import { ReactComponent as DatabaseIcon } from "../assets/images/icons/database.svg";
import { ReactComponent as MoreIcon } from "../assets/images/icons/more-horizontal.svg";

import AllAppRoutes from "../common/routes";
import BlobImg from "../assets/images/icons/blob.svg";


import _1 from "../assets/images/_1.jpg";
import FooterDisplay from "../components/footer";
import { fetchData, profileData } from '../slices/profileSlice';
import { _fetch_user_wallet_data, _get_all_transaction_data, _get_profile, _get_user_wallet, _get_user_wallet_history } from "../common/axios_services";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import AppRoutes from "../common/routes";
import WalletHistoryDetailPage from "../components/profile/history/wallet";
import TopNav from "../components/top-nav";
import WalletCard from "../components/WalletCard";


const WalletPage = () => {

    const majorSpinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const dispatch = useDispatch();
    const [majorPageSpinner, setMajorPageSpinner] = useState(true);
    const [currentHistDisp, setCurrentHistDisp] = useState("wallet");

    const [hideWalletBalance, setHideWalletBalance] = useState(false);
    const changeWalletBalanceVisibility = () => setHideWalletBalance(!hideWalletBalance);

    const [fixedNav, setFixed] = useState(false);
    const [walletData, setWalletData] = useState({});
    const cart = useSelector(state => state.userCart.cart);
    const [displayMode, setDisplayMode] = useState("content");
    const [historyData, setHistoryData] = useState({});
    // 

    const fetchUserWalletBalance = async () => {
        try {
            let fetchUserAccountBalance = await _fetch_user_wallet_data();
            if (fetchUserAccountBalance.data.status === "success") {
                setWalletData(fetchUserAccountBalance.data.data);
            } else {
                openNotificationWithIcon('error', 'An error occurred while loading wallet balance. Please reload page to try again')
            }
        } catch (err) {
            openNotificationWithIcon('error', 'An error occurred while loading wallet balance. Please reload page to try again')
        }
    }

    const Navigate = useNavigate();
    const jwt = localStorage.konnect_token;
    let jwt_token = localStorage.getItem('konnect_token');
    const token = useSelector(state => state.userProfile.token);
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
            placement: "bottomRight"
        });
    };

    const [loyaltyHis, setLoyaltyHis] = useState([]);
    const [transHis, setTransHis] = useState([]);
    const [totalTrans, setTotalTrans] = useState(0);

    const [wiseHis, setWiseHis] = useState([]);
    const [page, setPage] = useState(1);
    const [totalLoyalty, setTotalLoyalty] = useState(0);
    const [per_page, setPer_page] = useState(10);
    const [total_trans, setTotal_trans] = useState(0);
    const [fetchingLoyalty, setFetchingLoyalty] = useState(true)
    const [total_wise, setTotal_wise] = useState(0);
    const [pageLoy, setPageLoy] = useState(1);
    const [pageSizeTrans, setPageSizeTrans] = useState(10);
    const [pageSizeLoy, setPageSizeLoy] = useState(10);
    const [userLogInStatus, setUserLogInStatus] = useState(false);
    const [checkingUserLogInStatus, setCheckingUserLogInStatus] = useState(true);
    const [userData, setUserData] = useState({});
    const [userTransferData, setUserTransferData] = useState({});
    const [displayUserWallet, setDisplayUserWallet] = useState(true);

    const fetchUserWalletPayments = async () => {
        try {
            const getPayment = await _get_user_wallet_history({ page: pageLoy, page_size: pageSizeLoy });
            setLoyaltyHis(getPayment.data.data);
            setFetchingLoyalty(false);
            setTotalLoyalty(getPayment.data.meta.total);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token');
                    Navigate('/signin');
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', "An error occurred while loading loyalty data. Please reload page to try again")
            }
        }
    }
    const fetchUserTransactionData = async () => {
        try {
            let userWalletTrx = await _get_all_transaction_data({ page, page_size: pageSizeTrans });
            setLoyaltyHis(userWalletTrx.data.data);
            // setTransHis(userWalletTrx.data.data);
            setTotalTrans(userWalletTrx.data.meta.total);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin');
                    openNotificationWithIcon('error', "User Authorization expired. Please log in to continue");
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchUser = async () => {
        try {
            const user_profile = await _get_profile();
            dispatch(fetchData(true));
            dispatch(profileData(user_profile?.data?.data));
            let userData = user_profile?.data.data;
            setUserData(userData);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate(`/signin`)
                }
                dispatch(profileData(false))
                dispatch(fetchData(false))
            } else {
            }
        }
    }
    const fetchUserWallet = async () => {
        try {
            const user_profile = await _get_user_wallet();
            let userData = user_profile?.data.data;
            setUserTransferData(userData);
            setMajorPageSpinner(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate(`/signin`)
                }
                dispatch(profileData(false))
                dispatch(fetchData(false))
            } else {
            }
        }
    }

    const handleTitleCheckChange = () => {
        setDisplayUserWallet(!displayUserWallet)
    }

    useEffect(() => {
        if (jwt) {
            fetchUser();
            fetchUserWalletBalance();
            fetchUserWalletPayments();
            fetchUserTransactionData();
            setUserLogInStatus(true);
            fetchUserWallet();
            setCheckingUserLogInStatus(false);
        } else {
            setUserLogInStatus(false);
            setCheckingUserLogInStatus(false);
        }
        window.addEventListener('scroll', () => {
            const offset = window.scrollY;
            if (offset > 20) {
                setFixed(true);
            } else {
                setFixed(false);
            }
        })
    }, [])

    const setDisplayModeToContent = () => {
        setDisplayMode("content");
    }

    const handleHistoryDisplay = history => {
        setDisplayMode("history");
        setHistoryData(history);
    }


    const items = [
        {
            key: '1',
            label: (
                <Link to={`${AppRoutes.pay_bills}?rel=airtime`}>
                    Airtime
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link to={`${AppRoutes.pay_bills}?rel=data`}>
                    Data
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link to={`${AppRoutes.pay_bills}?rel=electricity`}>
                    Electricity
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link to={`${AppRoutes.pay_bills}?rel=tv`}>
                    TV
                </Link>
            ),
        }
    ];

    return (
        <React.Fragment>
            {
                displayMode === "content" ?
                    <div className="wallet-content wise9ja-content">
                        {
                            checkingUserLogInStatus ?
                                <div>
                                    <TopNav title="Your Wallet" />
                                    <div className="center-content">
                                        <div className="center-content-text">
                                            <Spin indicator={majorSpinnerIcon} />
                                        </div>
                                    </div>
                                </div>
                                :
                                userLogInStatus ?
                                    <div>
                                        <Spin indicator={majorSpinnerIcon} spinning={majorPageSpinner}>
                                            <div className="homepage wise9ja-disp">
                                                <div className="homepage-history-header">
                                                    <TopNav title="Your Wallet" />
                                                    <div className="contain">
                                                        <div className=" mt-9 mb-24">
                                                            <WalletCard
                                                                tierType = {userData?.tier?.split("_")[1]}
                                                                displayTitleCheck={displayUserWallet}
                                                                userTransferData={userTransferData}
                                                                majorPageSpinner={majorPageSpinner}
                                                                walletData={walletData}
                                                                handleTitleCheckChange={handleTitleCheckChange}
                                                            /> 
                                                        </div>
                                                        {/* <div className="amount-banner mt_5">
                                                            <div className="amount-banner-div">
                                                                <>
                                                                    <div className="grid-flex">
                                                                        <div>
                                                                            {
                                                                                !userData.is_sa ?
                                                                                    <p className="black-bg">Earn money per month</p>
                                                                                    : ""
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                userData.is_sa ?
                                                                                    <div>
                                                                                        <Link className="black-bg" to="/profile/sales-report">View Sales Report</Link>
                                                                                    </div> : ""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="amount-main-bar">
                                                                        <p>Account Number</p>
                                                                        <h3>{userTransferData.account_number}</h3>
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="grid-2">
                                                                            <div className="amount-banner-list">
                                                                                <p>Account Name</p>
                                                                                <h5>{userTransferData.account_name}</h5>
                                                                            </div>
                                                                            <div className="amount-banner-list">
                                                                                <p>Bank Name</p>
                                                                                <h5>{userTransferData.bank_name}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </div>
                                                            <div className="blob-cover">
                                                                <img src={BlobImg} alt="blob" />
                                                            </div>
                                                            <div className="blob-cover _2">
                                                                <img src={BlobImg} alt="blob" />
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="sec-story-banner mt_3"></div> */}
                                                        <div className="quick-action mt_3">
                                                            <div className="grid-3 mt_2 grid-card-actions">
                                                                <div className="card-action-box">
                                                                    <Link to={AllAppRoutes.withdraw_to_bank}>
                                                                        <div className="wise9ja-icon-cover">
                                                                            <DownloadIcon />
                                                                        </div>
                                                                        <p>Bank</p>
                                                                    </Link>
                                                                </div>
                                                                <div className="card-action-box">
                                                                    <Link to={AllAppRoutes.transfer_to_friend}>
                                                                        <div className="wise9ja-icon-cover">
                                                                            <GiftIcon />
                                                                        </div>
                                                                        <p>Konnect</p>
                                                                    </Link>
                                                                </div>
                                                                {
                                                                    userData.tier === "tier_2" ?
                                                                        <div className="card-action-box">
                                                                            <Dropdown menu={{
                                                                                items
                                                                            }}>
                                                                                <Link to="#">
                                                                                    <div className="wise9ja-icon-cover">
                                                                                        <DatabaseIcon />
                                                                                    </div>
                                                                                    <p>Pay Bills</p>
                                                                                </Link>
                                                                            </Dropdown>
                                                                        </div>
                                                                        :
                                                                        <div className="card-action-box">
                                                                            <a target="_blank" href="https://konnect.ws/signin/?redir=why-upgrade">
                                                                                <div className="wise9ja-icon-cover">
                                                                                    <DatabaseIcon />
                                                                                </div>
                                                                                <p>Upgrade</p>
                                                                            </a>
                                                                        </div>
                                                                }
                                                                {/* <div className="card-action-box">
                                                                    <Link to="#">
                                                                        <div className="wise9ja-icon-cover">
                                                                            <MoreIcon />
                                                                        </div>
                                                                        <p>More Info</p>
                                                                    </Link>
                                                                </div> */}
                                                                {/* <div className="card-action-box">
                                                                    <Link to={AllAppRoutes.transfer_to_friend}>
                                                                        <div className="wise9ja-icon-cover">
                                                                            <GiftIcon />
                                                                        </div>
                                                                        <p>More Info</p>
                                                                    </Link>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="content-display-content">
                                                    <div className="contai">
                                                        <div className="wise9ja-action-bar">
                                                        </div>
                                                        <div className="mt_3 history-data-detail">
                                                            <div className="contain">
                                                                <div className="grid-flex">
                                                                    <div>
                                                                        <h3 className="tile-header">Most Recent History</h3>
                                                                    </div>
                                                                    <div>
                                                                        <Link className="outside-link" to={AppRoutes.wallet_history}>View All</Link>
                                                                    </div>
                                                                </div>
                                                                <Divider style={{ margin: "10px 0px 20px" }} />
                                                                <div>
                                                                    {
                                                                        loyaltyHis.map((history, index) => (
                                                                            <React.Fragment key={`loy-${index}`}>
                                                                                <div
                                                                                    onClick={() => handleHistoryDisplay(history)}
                                                                                    className={`current-history-display ${index === 0 ? "first-history-disp" : ""}`} key={`loy-${index}`}>
                                                                                    <div className="disp-category">
                                                                                        {/* <h3>{history?.service?.slice(0, 1)?.toUpperCase()}T</h3> */}
                                                                                        {
                                                                                            history?.payment_type !== "credit" ?
                                                                                                <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                                                                                                :
                                                                                                <ion-icon class="down" name="arrow-down-circle-outline"></ion-icon>
                                                                                        }   
                                                                                    </div>
                                                                                    <div>
                                                                                        <h3>{history?.service?.slice(0, 1)?.toUpperCase()}{history?.service?.slice(1)} Transaction</h3>
                                                                                        <ul>
                                                                                            <li>{DateTime.fromISO(history?.timestamp).toLocaleString(DateTime.DATE_HUGE)};</li>
                                                                                            <li>{DateTime.fromISO(history.timestamp).toLocaleString(DateTime.TIME_24_SIMPLE)}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h3 className={`history-amount ${history?.payment_type === "credit" ? "green" : "red"} `}>{history?.payment_type === "credit" ? "+" : "-"}<span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                                            className="numeric" displayType={'text'} value={Math.round(Number(history?.total_amount) * 1).toFixed(2)} /></h3>
                                                                                        <p className={`status`}>{history?.payment_type}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <Divider style={{ margin: "20px 0px" }} />
                                                                            </React.Fragment>
                                                                        )) 
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <FooterDisplay />
                                            </div>
                                        </Spin>
                                    </div>
                                    :
                                    ""
                        }
                        <FooterDisplay />
                    </div>
                    :
                    <WalletHistoryDetailPage historyData={historyData} displayModeControl={setDisplayModeToContent} />
            }
        </React.Fragment>
    )
}

export default WalletPage;