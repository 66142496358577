import "../assets/css/shop.css";

import React, { useState, useEffect } from "react";

import { Divider, notification, Select } from "antd";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Link, useNavigate } from "react-router-dom";

import 'swiper/css';
import AppRoutes from "../common/routes";
import TopCircle from "../components/top-circle";

import Image1 from "../assets/images/ewedu.png";
import Image2 from "../assets/images/yam.png";
import Image3 from "../assets/images/pineapple.png";

import Wapp from "../assets/images/wapp.jpg";

import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as PlusIcon } from "../assets/images/icons/plus-circle.svg";
import { ReactComponent as MinusIcon } from "../assets/images/icons/minus-circle.svg";
import { ReactComponent as CartIcon } from "../assets/images/icons/mobilecart.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";
import { ReactComponent as TrashIcon } from "../assets/images/icons/trash.svg";
import { ReactComponent as SearchIcon } from "../assets/images/icons/mobilecart.svg";
import FooterDisplay from "../components/footer";
import OtherProducts from "../components/other-products";
import { LoadingOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { addToCart, updateCart } from '../slices/cartSlice';
import NumberFormat from 'react-number-format';
import axiosCall from '../common/axios_call';
import { _delivery_info } from "../common/axios_services";



const CartPage = () => {

    const Navigate = useNavigate();
    const [cartQuantity, setCartQuantity] = useState(1);

    let url = new URLSearchParams(window.location.search);
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
            placement: "bottom-right"
        });
    };

    const [referralCode,] = useState(url.get('konnectrfc'));
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [orderTotals, setOrderTotals] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [trend, setTrend] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector(state => state.userProfile.dataFetched);
    const [minOrderDelivery, setMinOrderDelivery] = useState(0);

    const options = [];
    for (let index = 1; index <= 20; index++) {
        options.push(index);
    };
    const { Option } = Select;

    let cart_info = useSelector(state => state.userCart.cart)

    const reduWeight = (total, num) => {
        return total + num.total_weight;
    }
    const reduPrice = (total, num) => {
        return total + num.total_price;
    }
    const reduDiscount = (total, num) => {
        return total + num.discount;
    }
    const reduCashback = (total, num) => {
        return total + num.cashback;
    }

    const DeliveryFees = async () => {
        const local_cart = JSON.parse(localStorage.getItem('konnect_cart'))
        if (local_cart) {
            const data = {
                app: "web",
                sub_amount: `${local_cart.data.reduce(reduPrice, 0)}`,
                discounts: `${local_cart.data.reduce(reduDiscount, 0)}`,
                cashback: `${local_cart.data.reduce(reduCashback, 0)}`,
                coupon: "0",
            }
            try {
                const delivery_info = await _delivery_info(data)
                // console.log(delivery_info)
                setOrderTotals(delivery_info.data)
            } catch (err) {
                if (err.response) {
                    if (err.response === "Unauthorized") {
                        navigate('/')
                        openNotificationWithIcon('error', err.response.data.message);
                    } else {
                        openNotificationWithIcon('error', err.response.data.message);
                    }
                } else {
                    openNotificationWithIcon('error', err.message);
                }
            }
        }
    }

    useEffect(() => {
        const local_cart = JSON.parse(localStorage.getItem('konnect_cart'))
        axiosCall('/products?page=1&per_page=14')
            .then(res => {
                setTrend(res.data.data);
            })
            .catch(err => {
            })
        dispatch(addToCart(local_cart));
        DeliveryFees();
    }, [updateCount]);

    const updateCartQuantity = (value, id) => {
        setSpinnerLoading(true);
        const get_cart = JSON.parse(localStorage.getItem('konnect_cart'))
        let all_data = get_cart
        const add_qty = get_cart.data.map(obj => {
            if (obj.product_id == id) {
                return {
                    id: obj.id,
                    product_id: obj.product_id,
                    quantity: value,
                    timestamp: obj.timestamp,
                    product_name: obj.product_name,
                    product_image: obj.product_image,
                    product_price: obj.product_price,
                    description: obj.description,
                    weight: obj.weight,
                    discount_rate: obj.discount_rate,
                    discount: obj.product_price * obj.discount_rate * 0.01 * value,
                    total_weight: value * obj.weight,
                    total_price: value * obj.product_price,
                    cashback: value * obj.cashback * obj.product_price * 0.01,
                };
            }
            return obj;
        })
        all_data.data = add_qty
        dispatch(addToCart(all_data))
        localStorage.setItem('konnect_cart', JSON.stringify(all_data))
        setOrderTotals(false)
        setUpdateCount(updateCount + 1)
        setSpinnerLoading(false);
    }

    const removeProductFromCart = (id) => {
        console.log("hit")
        const local_cart = JSON.parse(localStorage.getItem('konnect_cart'))
        const indexOfObject = local_cart.data.findIndex((object) => {
            return object.product_id === id;
        });

        if (indexOfObject !== -1) {
            local_cart.data.splice(indexOfObject, 1);
        }

        setOrderTotals(false);
        setUpdateCount(updateCount + 1);
        localStorage.setItem('konnect_cart', JSON.stringify(local_cart))
        dispatch(addToCart(local_cart))
    }

    return (
        <div className="homepage cart-page">
            <div className="small-page-title contain">
                <div className="grid-flex">
                    <div
                        onClick={() => Navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    <h5>Your Cart</h5>
                        <div className="chat">
                            <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank">
                                <ion-icon name="chatbubbles-outline"></ion-icon>
                            </a>
                        </div>
                </div>
            </div>
            <div className="display-main-content cart-content mt_3">
                <div className="hero-sect">
                    <div className="contain">
                        <div className="extra-data-message red">
                            <div className="extra-data-content">
                                <h3>Buy in bulk, we deliver for free.</h3>
                                <div>
                                    <p>and you can pay after delivery or instalments.</p>
                                </div>
                            </div>
                            <div className="extra-div _1"></div>
                            <div className="extra-div _2"></div>
                            <div className="extra-div _3"></div>
                        </div>
                    </div>
                </div>
                <div className="contai mt_3">
                    <div className="cart-product-bar">
                        {cart_info?.data?.map((product, index) => (
                            <div key={index} className="cart-image-showcase">
                                <div className="cart-img-box">
                                    <img src={product.product_image} alt="ewedu" />
                                </div>
                                <div>
                                    <div className="cart-amount-detail">
                                        <div>
                                            <h3>{product.product_name}</h3>
                                        </div>
                                        <div className="grid-fle">
                                            <div>
                                                <Select defaultValue={product.quantity} onChange={(value) => updateCartQuantity(value, product.product_id)} className="select-quantity" style={{ width: 80, margin: '0% auto' }}>
                                                    {options.map((option) => (
                                                        <Option value={option} key={option}>{option}</Option>
                                                    ))
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-fle">
                                        <div className="grid-flex">
                                            <div>
                                                <p className="cart-item-price"><span className="currency">NGN</span>
                                                    <NumberFormat value={product.total_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} />
                                                </p>
                                            </div>
                                            <div className="grid-flex">
                                                <div></div>
                                                <div>
                                                    <TrashIcon onClick={() => removeProductFromCart(product.product_id)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="contain mt_3">
                    <OtherProducts />
                </div>
                <div className="pt_5">
                    <div className="divider-line"></div>
                    <div className="contain">
                        <div className="price-listing pt_5">
                            <div className="contain">
                                <ul>
                                    <li className="grid-flex">
                                        <div>
                                            <h4>Order Size</h4>
                                        </div>
                                        <div>
                                            <span className="second"></span>
                                            <h2>{cart_info?.data?.reduce(reduWeight, 0).toFixed(2)} kg</h2>
                                        </div>
                                    </li>
                                    <li className="grid-flex">
                                        <div>
                                            <h4>Subtotal</h4>
                                        </div>
                                        <div>
                                            <h2><span className="currency">NGN</span>
                                                <NumberFormat prefix="" value={cart_info?.data?.reduce(reduPrice, 0).toFixed(2)} className="foo"
                                                    displayType={'text'} thousandSeparator={true} /></h2>
                                        </div>
                                    </li>
                                    <li className="grid-flex">
                                        <div>
                                            <h4>Delivery Fee</h4>
                                        </div>
                                        <div>
                                            <h2><span className="currency">NGN</span>
                                                <NumberFormat prefix="" value={Number(orderTotals.delivery_fee).toFixed(2)} className="foo"
                                                    displayType={'text'} thousandSeparator={true} /></h2>
                                        </div>
                                    </li>
                                    <li className="grid-flex">
                                        <div>
                                            <h4>Discounts</h4>
                                        </div>
                                        <div>
                                            <h2><span className="currency">NGN</span>
                                                <NumberFormat prefix="" value={Number(orderTotals.discounts).toFixed(2)} className="foo"
                                                    displayType={'text'} thousandSeparator={true} /></h2>
                                        </div>
                                    </li>
                                    <li className="grid-flex">
                                        <div>
                                            <h4>Tax</h4>
                                        </div>
                                        <div>
                                            <h2><span className="currency">NGN</span>
                                                <NumberFormat prefix="" value={Number(orderTotals.tax_fee).toFixed(2)} className="foo"
                                                    displayType={'text'} thousandSeparator={true} /></h2>
                                        </div>
                                    </li>
                                    <li className="grid-flex">
                                        <div>
                                            <h4>Coupons</h4>
                                        </div>
                                        <div>
                                            <h2><span className="currency">NGN</span>
                                                <NumberFormat prefix="" value={Number(orderTotals.coupon).toFixed(2)} className="foo"
                                                    displayType={'text'} thousandSeparator={true} /></h2>
                                        </div>
                                    </li>
                                    <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                    <li className="grid-flex">
                                        <div>
                                            <h4>Total</h4>
                                        </div>
                                        <div>
                                            <h2><span className="currency">NGN</span>
                                                <NumberFormat prefix="" value={Number(orderTotals.total_amount).toFixed(2)} className="foo"
                                                    displayType={'text'} thousandSeparator={true} /></h2>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="cart-to-checkout">
                            <div className="cart-button-showcase">
                                <Link to="/checkout" className="btn-green">Proceed to Checkout</Link>
                                {/* <button className="btn-gray mt_2">Add More Items</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartPage;