import "../assets/css/mini.css";

import React, { useState, useEffect } from "react";

import { Divider, notification, Spin } from "antd";
import { DateTime } from 'luxon';
import 'swiper/css';

import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";

import AllAppRoutes from "../common/routes";

import _1 from "../assets/_1s.jpg";

import { ReactComponent as ArchiveIcon } from "../assets/images/icons/archive.svg";
import { ReactComponent as PieChartIcon } from "../assets/images/icons/pie-chart.svg";
import { ReactComponent as MoreInfo } from "../assets/images/icons/more-horizontal.svg";

import FolderIcon from "../assets/images/illustrations/12_folder.png";
import BlobImg from "../assets/images/icons/blob.svg";

import { _complete_wise9ja_payment, _confirm_user_transfer_pin, _fetch_user_wallet_data, _fetch_user_wise9ja_data, _fetch_wise9ja_plans, _get_all_account_details, _get_all_wise9ja, _get_profile, _get_user_account_history, _wise9ja_topup } from '../common/axios_services';
import { fetchData, profileData } from '../slices/profileSlice';

import EmptyGIF from "../assets/images/illustrations/no-entry.gif";

import FooterDisplay from "../components/footer";
import Wise9jaHistoryDetailPage from "../components/profile/history/wise9ja";
import AppRoutes from "../common/routes";
import TopNav from "../components/top-nav";

const Wise9jaPage = () => {

    const dispatch = useDispatch();
    const token = useSelector(state => state.userProfile.token);
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
            placement: "bottomRight"
        });
    };
    const cart = useSelector(state => state.userCart.cart);

    // transaction code confirmation starts


    const majorSpinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const [majorPageSpinner, setMajorPageSpinner] = useState(true);
    const [displayMode, setDisplayMode] = useState("content");
    const [historyData, setHistoryData] = useState({});

    const jwt = localStorage.konnect_token;

    // wise9ja process starts

    let jwt_token = localStorage.getItem('konnect_token');


    const getProfile = async () => {
        try {
            const profile = await _get_profile();
            if (profile.data.status === "success") {
                dispatch(fetchData(true))
                dispatch(profileData(profile.data.data))
            } else {
                dispatch(fetchData(false))
                dispatch(profileData(false))
            }
        } catch (err) {
            dispatch(fetchData(false))
            dispatch(profileData(false))
        }
    }

    const Navigate = useNavigate();

    const [walletBal, setWalletBal] = useState(0)
    const [voucherBal, setVoucherBal] = useState(0)
    const [ledgerBal, setLedgerBal] = useState(0);
    const [wise9jaBal, setWise9jaBal] = useState(0);

    const [wiseHis, setWiseHis] = useState([]);
    const [totalWise, setTotalWise] = useState(0);
    const [page, setPage] = useState(1)
    const [page_size, setPage_size] = useState(5)
    const [userLogInStatus, setUserLogInStatus] = useState(false);

    const [fixedNav, setFixed] = useState(false);

    const [checkingUserLogInStatus, setCheckingUserLogInStatus] = useState(true);

    // fetch user wise9ja plan
    const fetchUserWise9jaPlan = async () => {
        try {
            let userWise9jaTrx = await _fetch_user_wise9ja_data({ page, page_size });
            setWiseHis(userWise9jaTrx.data.data);
            setTotalWise(userWise9jaTrx.data.meta.total);
            setMajorPageSpinner(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin');
                }
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    // fetch user wise9ja plan
    const fetchDashboardDetails = async () => {
        try {
            const getDashDetails = await _get_user_account_history()
            setWalletBal(getDashDetails.data.data.wallet_balance);
            setVoucherBal(getDashDetails.data.data.wisenija_cash_earned);
            setWise9jaBal(getDashDetails.data.data.wisenija_sub);
            setLedgerBal(getDashDetails.data.data.wisenija_cash_to_earn);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin');
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', "An error occurred while loading user-details.")
            }
        }
    }

    React.useEffect(() => {
        if (jwt) {
            getProfile();
        }
    }, [dispatch, token, jwt_token])

    useEffect(() => {
        if (jwt) {
            fetchDashboardDetails();
            fetchUserWise9jaPlan();
            setUserLogInStatus(true);
            setCheckingUserLogInStatus(false);
        } else {
            setUserLogInStatus(false);
            setCheckingUserLogInStatus(false);
        }
        window.addEventListener('scroll', () => {
            const offset = window.scrollY;
            if (offset > 20) {
                setFixed(true);
            }
            else {
                setFixed(false);
            }
        })
    }, [])

    const setDisplayModeToContent = () => {
        setDisplayMode("content");
    }

    const handleHistoryDisplay = history => {
        setDisplayMode("history");
        setHistoryData(history);
    }

    return (
        <React.Fragment>
            {
                displayMode === "content" ?
                    <div className="wise9ja-content wise">
                        {
                            checkingUserLogInStatus ?
                                <div>
                                    <TopNav title="Your Wise9ja Plans" />
                                    <div className="center-content">
                                        <div className="center-content-text">
                                            <Spin indicator={majorSpinnerIcon} />
                                        </div>
                                    </div>
                                </div>
                                :
                                userLogInStatus ?
                                    <div>
                                        <Spin indicator={majorSpinnerIcon} spinning={majorPageSpinner}>
                                            <div className="homepage wise9ja-disp">
                                                <div className="homepage-history-header">
                                                    <div>
                                                        <TopNav title="Your Wise9ja Plans" />
                                                        <div className="contain">
                                                            <div className="amount-banner mt_5">
                                                                <div className="amount-banner-div">
                                                                    <div className="grid-flex">
                                                                        <div>
                                                                            <p className="black-bg">Earn money per month</p>
                                                                        </div>
                                                                        <div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="amount-main-bar">
                                                                        <p>Wise9ja Amount</p>
                                                                        <h3><span className="currency">NGN </span><NumberFormat thousandSeparator={true} prefix={''}
                                                                            className="numeric" displayType={'text'} value={Math.floor(+wise9jaBal).toFixed(2)} /></h3>
                                                                    </div>
                                                                    <div className="">
                                                                        <div className="grid-2">
                                                                            <div className="amount-banner-list">
                                                                                <p>Cash Earned</p>
                                                                                <h5><span className="currency">NGN </span><NumberFormat thousandSeparator={true} prefix={''}
                                                                                    className="numeric" displayType={'text'} value={Math.floor(+voucherBal).toFixed(2)} /></h5>
                                                                            </div>
                                                                            <div className="amount-banner-list">
                                                                                <p>Cash to Earn</p>
                                                                                <h5><span className="currency">NGN </span><NumberFormat thousandSeparator={true} prefix={''}
                                                                                    className="numeric" displayType={'text'} value={Math.floor(+ledgerBal).toFixed(2)} /></h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="blob-cover">
                                                                    <img src={BlobImg} alt="blob" />
                                                                </div>
                                                                <div className="blob-cover _2">
                                                                    <img src={BlobImg} alt="blob" />
                                                                </div>
                                                            </div>
                                                            <div className="quick-action mt_3">
                                                                <div className="grid-3 mt_2 grid-card-actions">
                                                                    <div className="card-action-box">
                                                                        <Link to={AppRoutes.start_earning}>
                                                                            <div className="wise9ja-icon-cover">
                                                                                <ArchiveIcon />
                                                                            </div>
                                                                            <p>New Plan</p>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="card-action-box">
                                                                        <Link to={AppRoutes.how_wise9ja_works}>
                                                                            <div className="wise9ja-icon-cover">
                                                                                <MoreInfo />
                                                                            </div>
                                                                            <p>More Info</p>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="card-action-box">
                                                                        <Link to={AllAppRoutes.wise9ja_history}>
                                                                            <div className="wise9ja-icon-cover">
                                                                                <PieChartIcon />
                                                                            </div>
                                                                            <p>History</p>
                                                                        </Link>
                                                                    </div>
                                                                    {/*
                                                                    <div className="card-action-box">
                                                                        <Link to={AllAppRoutes.fund_user_wallet}>
                                                                            <div className="wise9ja-icon-cover">
                                                                                <PlusIcon />
                                                                            </div>
                                                                            <p>Deposit</p>
                                                                        </Link>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gray-bg-cover content-display-content">
                                                    <div className="contain">
                                                        <div className="wise9ja-action-bar">

                                                        </div>
                                                        <div className="mt_4 history-data-detail">
                                                            <div className="grid-flex">
                                                                <div>
                                                                    <h3 className="tile-header">Most Recent Wise9ja History</h3>
                                                                </div>
                                                                <div>
                                                                    <Link className="outside-link" to={AppRoutes.wise9ja_history}>View All</Link>
                                                                </div>
                                                            </div>
                                                            <Divider style={{ margin: "10px 0px 20px" }} />
                                                            <div className="contai">
                                                                {
                                                                    !majorPageSpinner ?
                                                                        wiseHis.length ?
                                                                            wiseHis.slice(0,5).map((history, index) => (
                                                                                <React.Fragment key={index}>
                                                                                    <div key={index}
                                                                                        onClick={() => handleHistoryDisplay(history)}
                                                                                        className="first-history-disp current-history-display">
                                                                                        <div className="disp-category">
                                                                                            <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <h3>Wise9ja Transaction</h3>
                                                                                            <ul>
                                                                                                <li>{DateTime.fromISO(history.created_at).toLocaleString(DateTime.DATE_HUGE)}</li>
                                                                                                {/* <li>{DateTime.fromISO(history.created_at).toLocaleString(DateTime.TIME_24_SIMPLE)}</li> */}
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div>
                                                                                            <h3 className="history-amount red">-<span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                                                className="numeric" displayType={'text'} value={(+history.amount).toFixed(2)} /></h3>
                                                                                            <p className="status">successful</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Divider style={{ margin: "20px 0px" }} />
                                                                                </React.Fragment>
                                                                            ))
                                                                            :
                                                                            <div>
                                                                                <div className="empty-data">
                                                                                    <div>
                                                                                        <img src={EmptyGIF} alt="failure gif" />
                                                                                        <p>No data found</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Spin>
                                    </div>
                                    :
                                    <div>
                                        <TopNav title="Your Wise9ja Plans" />
                                        <div className="center-content">
                                            <div className="center-content-text">
                                                <img src={FolderIcon} alt="folder" />
                                                <h4>Login Required</h4>
                                                <p>You need to login to access this route. Sign-in and get richer daily with Wise9ja.</p>
                                                <div>
                                                    <div className="">
                                                        <Link className="btn-base" to={AllAppRoutes.signin}>Login now</Link>
                                                    </div>
                                                    {/* <div className="mt_1">
                                            <Link className="btn-blank" to={AllAppRoutes.how_wise9ja_works}>How Wise9ja Works</Link>
                                        </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }
                        <FooterDisplay />
                    </div>
                    :
                    <Wise9jaHistoryDetailPage historyData={historyData} displayModeControl={setDisplayModeToContent} />
            }
        </React.Fragment>
    )
}

export default Wise9jaPage;