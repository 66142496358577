import React from 'react';
import {Drawer} from "antd";
import { ReactComponent as Cancel } from '../assets/images/icons/x.svg';




const RightDrawerTemplate = ({closeDrawer, openDrawer, width, title, children }) => {
    return (
        <Drawer
            placement="right"
            onClose={closeDrawer}
            open={openDrawer}
            width={ width }
            className="checkoutDrawer_wrapper"
        >
            <div className="w-full px-8 py-4">
                <div className="drawer_header py-3 flex justify-between">
                    <h2 className="text-4xl"> {title} </h2>
                    <Cancel onClick={closeDrawer} className="cancel cursor-pointer"/>
                </div>
                <hr />
                {children}
            </div>
        </Drawer>
    );
};

export default RightDrawerTemplate;