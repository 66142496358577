import React from 'react';
import CustormerImg from "../assets/images/icons/customer.svg";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoutIcon } from "../assets/images/icons/black_logout.svg";
import { ReactComponent as CameraIcon } from "../assets/images/icons/camera.svg";



export const AccountHeader = ({userData, updateHandle, handleDrawer  }) => {
    const Navigate =  useNavigate();

    const logout = () => {
        localStorage.removeItem("konnect_token");
        Navigate("/signin");
      };
  return (
    <div className='bg-[#f3f3f366] py-4 px-4'>
        <div className="flex justify-between items-center">
            <div className="chat">
                <a
                href="https://tawk.to/chat/61590f9625797d7a8901fd89/default"
                target="_blank" rel="noreferrer"
                >
                <img
                    src={CustormerImg}
                    className="customer"
                    alt="customer"
                />
                </a>
            </div>
            <div onClick={logout} className="w-[5%] h-[5%]">
                <LogoutIcon />
            </div>
        </div>
        <div className="flex flex-col justify-center gap-y-2 items-center">
            <div className="avatar-blk-cover border-2 border-[green]">
                {!userData?.image?.length ? (
                    <CameraIcon
                    onClick={() => updateHandle(true)}
                    />
                ) : (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img
                    onClick={() => updateHandle(true)}
                    src={userData.image}
                    className='w-full h-full'
                    alt={`${userData.first_name} profile picture`}
                    />
                )}
            </div>
            <div className="flex flex-col gap-y-2  justify-center">
                <h4 className="text-black  text-[18px] font-gilroyBold uppercase " >
                    {userData.first_name} {userData.last_name}
                </h4>
                <button
                    onClick={handleDrawer}
                    className="text-black text-lg font-gilroyBold"
                    to=""
                >
                    View referral code
                </button>
            </div>

        </div>


    </div>
  )
}
