import React, { useEffect, useState } from "react";

import { Checkbox, Input, Select, Spin, Tabs, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";

import TopNav from "../../../components/top-nav";
import { _send_user_complaint } from "../../../common/axios_services";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import NaijaStates from "naija-state-local-government";

const JoinUsPage = () => {
  const Navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(false);
  const [allLgas, setAllLgas] = useState([]);
  const [userLivingState, setUserLivingState] = useState("");
  const [flyerChecked, setFlyerChecked] = useState(true);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      description: message,
    });
  };

  // start form handlers
  const digitsOnly = (value) => /^\d+$/.test(value);

  const contactValidator = yup.object().shape({
    gender: yup.string().required("Gender field is required"),
    age_group: yup.string().required("Age group field is required"),
    email: yup
      .string()
      .email("Email address is not valid")
      .required("Email address field is required"),
    lga: yup
      .string()
      .required("Local government is required")
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactValidator),
  });

  const onChangeState = (value) => {
    if (value) {
      const allLGAsinAState = NaijaStates.lgas(`${value}`);
      setUserLivingState(value);
      setAllLgas(allLGAsinAState.lgas);
    }
  };

  const onChange = (e) => {
    setFlyerChecked(e.target.checked);
  };

  const completeJoinUs = async (e) => {
    try {
      const contact = await _send_user_complaint(e);
      reset();
      openNotificationWithIcon(
        "success",
        "Thank you for reaching out to us. We will get back to you soon"
      );
    } catch (err) {
      if (err?.response?.data?.message) {
        openNotificationWithIcon("error", err?.response?.data?.message);
      } else if (err?.code === "ERR_NETWORK") {
        openNotificationWithIcon(
          "error",
          "An error occurred while completing request. Please check network and try again."
        );
      } else {
        openNotificationWithIcon(
          "error",
          "An error occurred while completing request. Please try again."
        );
      }
      setLoadingData(false);
    }
  };

  return (
    <div className="display-page joinus">
      <TopNav title="Reach out to us" />
      <div className="display-main-content mt_2">
        <div className="hero-sect">
          <div className="container">
            <div>
              <div className="mt_2">
                <div>
                  <div className="auth-sect-div">
                    <h3 className="auth-sect-title">
                      You're welcome to the Konnect Marketing Team recruitment.
                    </h3>
                  </div>
                </div>
                <form onSubmit={handleSubmit(completeJoinUs)}>
                  <div className="form-flex-2">
                    <div className="form_group">
                      <label>Your gender</label>
                      <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                          <Select style={{ height: "5rem", width: "100%" }} {...field}>
                            <Select.Option>Male</Select.Option>
                            <Select.Option>Female</Select.Option>
                          </Select>
                        )}
                      />
                      {errors.gender && (
                        <p className="form-error">
                          {errors.gender.message}
                        </p>
                      )}
                    </div>
                    <div className="form_group">
                      <label>Your age group</label>
                      <Controller
                        name="age_group"
                        control={control}
                        render={({ field }) => (
                          <Input
                            name="age_group"
                            {...field}
                            id="age_group"
                            style={{ height: "5rem" }}
                          />
                        )}
                      />
                      {errors.age_group && (
                        <p className="form-error">{errors.age_group.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="form_group">
                    <label htmlFor="state">Your State of residence</label>
                    <Controller
                      name="state"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          placeholder="Select state"
                          onChange={onChangeState}
                          name="state"
                          id="state"
                          style={{ width: "100%", height: "5rem" }}
                        >
                          <Select.Option value="lagos">Lagos</Select.Option>
                        </Select>
                      )}
                    />
                  </div>
                  <div className="form_group">
                    <label>Your LGA</label>
                    <Controller
                      name="lga"
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="Select LGA"
                          {...field}
                          name="lga"
                          style={{ width: "100%", height: "5rem" }}
                        >
                          {allLgas.map((lga, index) => (
                            <Select.Option key={index} value={lga}>
                              {lga}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.lga && (
                      <p className="form-error">
                        {errors.lga.message}
                      </p>
                    )}
                  </div>
                  <div className="form_group">
                    <Checkbox onChange={onChange}>
                      I will refer and share 100 fliers weekly
                    </Checkbox>
                    {errors.message && (
                      <p className="form-error">{errors.message.message}</p>
                    )}
                  </div>
                  <div className="welcome-buttons mt_20">
                    <div className="welcome-buttons-content">
                      {!loadingData ? (
                        <button className="btn-green">Join our WhatsApp</button>
                      ) : (
                        <button className="btn-green" disabled>
                          <Spin indicator={antIcon} />
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FooterDisplay /> */}
    </div>
  );
};

export default JoinUsPage;
