import React, { useState } from "react";

const PinInput = ({ length = 6, onChange, name }) => {
    const [values, setValues] = useState(Array(length).fill(""));

    const handleInputChange = (e, index) => {
        const newValue = e.target.value;
        if (!/^\d*$/.test(newValue)) return; // Only allow numeric input
        const newValues = [...values];
        newValues[index] = newValue;
        setValues(newValues);
        onChange(newValues.join("")); // Combine the values and pass to parent

        // Focus on the next input box
        if (newValue && index < length - 1) {
            const nextSibling = document.getElementById(`${name}-${index + 1}`);
            if (nextSibling) nextSibling.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !values[index] && index > 0) {
            const prevSibling = document.getElementById(`${name}-${index - 1}`);
            if (prevSibling) prevSibling.focus();
        }
    };

    return (
        <div style={{ display: "flex", gap: "8px" }}>
            {Array.from({ length }).map((_, index) => (
                <input
                    key={index}
                    id={`${name}-${index}`}
                    type="tel"
                    inputMode="numeric"
                    maxLength="1"
                    value={values[index] || ""}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className={` ${length === 4 && "w-20 h-20"}  w-16 h-16 text-center text-xl font-semibold border rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500`}
                />
            ))}
        </div>
    );
};

export default PinInput;
