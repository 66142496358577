import { createSlice } from "@reduxjs/toolkit";

// Helper function to get cart by restaurantId
const getCartByRestaurant = (restaurantId) => {
    const storedCart = localStorage.getItem(`restaurantCart_${restaurantId}`);
    return storedCart ? JSON.parse(storedCart) : [];
};

const initialState = {
    items: [],
    restaurantId: null,
};

const restaurantCartSlice = createSlice({
    name: "restaurantCart",
    initialState,
    reducers: {
        setRestaurant(state, action) {
            const { restaurantId } = action.payload;
            state.restaurantId = restaurantId;
            // Load the cart from localStorage
            const storedCart = getCartByRestaurant(restaurantId);
            // Ensure we have default details for each item in the cart
            state.items = storedCart.map(item => ({
                ...item,
                detail: item.detail || { public_price: item.public_price, discount: item.discount , product_name: item.product_name }, // Ensure we have a default for missing details
            }));
        },
        addToCart(state, action) {
            const { restaurantId, productId, quantity, public_price, discount, product_name, extras = [] } = action.payload;

            // Set restaurantId if not already set
            if (!state.restaurantId) {
                state.restaurantId = restaurantId;
                const storedCart = getCartByRestaurant(restaurantId);
                state.items = storedCart.map(item => ({
                    ...item,
                    detail: item.detail || { public_price: item.public_price, discount: item.discount, product_name: item.product_name },
                }));
            }

            // Map extras to ensure each extra has a name
            const updatedExtras = extras.map(extra => {
                if (!extra.name) {
                    const extraDetail = extra.item || {}; // Extract item details if not provided
                    extra.name = extraDetail.name || "Unknown Extra"; // Fallback if no name
                }
                return extra;
            });

            // Find product in the cart
            const indexProductId = state.items.findIndex(item => item.productId === productId);

            if (indexProductId >= 0) {
                // Update existing product quantity and extras
                state.items[indexProductId].quantity += quantity;

                // Merge extras (add new ones or update quantities of existing ones)
                const existingExtras = state.items[indexProductId].extras || [];
                updatedExtras.forEach(extra => {
                    const extraIndex = existingExtras.findIndex(e => e.id === extra.id);
                    if (extraIndex >= 0) {
                        existingExtras[extraIndex].quantity += extra.quantity; // Update quantity if extra exists
                    } else {
                        existingExtras.push(extra); // Add new extra
                    }
                });

                state.items[indexProductId].extras = existingExtras;
            } else {
                // Add new product with complete details and extras
                state.items.push({
                    productId,
                    quantity,
                    detail: {
                        public_price,
                        discount,
                        product_name,
                    },
                    extras: updatedExtras, // Add extras to the cart item
                });
            }

            // Save to localStorage
            localStorage.setItem(`restaurantCart_${state.restaurantId}`, JSON.stringify(state.items));
        },

        changeQuantity(state, action) {
            const { productId, quantity, extras } = action.payload;
            const indexProductId = state.items.findIndex(item => item.productId === productId);

            if (indexProductId >= 0) {
                // Update the product quantity
                if (quantity > 0) {
                    state.items[indexProductId].quantity = quantity;
                } else {
                    // Remove product if quantity is 0
                    state.items.splice(indexProductId, 1);
                }

                // If extras are provided, update them
                if (extras) {
                    state.items[indexProductId].extras = extras; // Update extras directly
                }
            }

            // Save updated state to localStorage
            localStorage.setItem(
                `restaurantCart_${state.restaurantId}`,
                JSON.stringify(state.items)
            );
        }

        // changeQuantity(state, action) {
        //     const { productId, quantity } = action.payload;
        //     const indexProductId = state.items.findIndex(item => item.productId === productId);
        //
        //     if (indexProductId >= 0) {
        //         if (quantity > 0) {
        //             // Update quantity but retain detail
        //             state.items[indexProductId].quantity = quantity;
        //         } else {
        //             // Remove the product if quantity is zero
        //             state.items.splice(indexProductId, 1);
        //         }
        //     }
        //     localStorage.setItem(
        //         `restaurantCart_${state.restaurantId}`,
        //         JSON.stringify(state.items)
        //     );
        // }
    },
});

export const { setRestaurant, addToCart, changeQuantity } = restaurantCartSlice.actions;

export default restaurantCartSlice.reducer;
