import React, { useState, useEffect } from "react";
import { Modal } from "antd";

const DiscountModal = ({ isVisible, onClose }) => {
    // Set initial expiration dates
    const initialDiscount450Expiry = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000); // 7 days
    const initialDiscount30Expiry = new Date(Date.now() + 14 * 24 * 60 * 60 * 1000); // 14 days

    // Helper to calculate remaining days
    const calculateDaysLeft = (expiryDate) => {
        const now = new Date();
        const diffTime = expiryDate - now; // Difference in milliseconds
        return Math.max(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 0); // Convert to days, ensure non-negative
    };

    const [discount450, setDiscount450] = useState(calculateDaysLeft(initialDiscount450Expiry)); // Countdown for NGN 450
    const [discount30, setDiscount30] = useState(calculateDaysLeft(initialDiscount30Expiry)); // Countdown for 30%

    useEffect(() => {
        const interval = setInterval(() => {
            // Update NGN 450 countdown (permanent expiry)
            setDiscount450((prev) => (prev > 0 ? prev - 1 : 0));

            // Update 30% countdown and reset if expired
            setDiscount30((prev) => {
                if (prev > 0) {
                    return prev - 1;
                } else {
                    return 14; // Reset to 14 days when it reaches 0
                }
            });
        }, 24 * 60 * 60 * 1000); // Update every 24 hours

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    useEffect(() => {
        // Automatically close modal if both discounts expire (only applies to NGN 450 since 30% resets)
        if (discount450 === 0 && discount30 === 0) {
            onClose();
        }
    }, [discount450, discount30, onClose]);

    return (
        <Modal
            open={isVisible}
            onCancel={onClose}
            footer={null}
            className="max-w-md mx-auto"
        >
            <div className="p-4">
                <h2 className="text-2xl font-bold text-center text-gray-800">
                    Use your discount TODAY
                </h2>
                <div className="mt-4 space-y-4">
                    {discount450 > 0 && (
                        <div className="p-4 border rounded-lg bg-green-200">
                            <h3 className="text-md font-semibold text-gray-700">
                                ₦450 discount per order
                            </h3>
                            <p className="text-[12px] text-gray-600">Expires in {discount450} days</p>
                            <p className="text-[12px] text-gray-600">Order above <span className="font-bold">₦4,500</span> </p>
                        </div>
                    )}
                    {discount30 > 0 && (
                        <div className="p-4 border rounded-lg bg-green-200">
                            <h3 className="text-md font-semibold text-gray-700">
                                30% discount on 4th order
                            </h3>
                            <p className="text-[12px] text-gray-600">Expires in {discount30} days</p>
                            <p className="text-[12px] text-gray-600">10% on first 3 orders</p>
                            <p className="text-[12px] text-gray-600">Order above <span
                                className="font-bold">₦3,000</span> </p>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default DiscountModal;
